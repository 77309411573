import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Swiper, SwiperSlide } from "swiper/react";
const unidecode = require("unidecode");
function Category() {
  const { categorySlug = null } = useParams();
  const [shimmer, setShimmer] = useState(false);
  const [ArrayCategories, setArrayCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  function transformToSlug(inputString) {
    const slug = unidecode(inputString)
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");

    return slug;
  }
  useEffect(() => {
    setShimmer(false);
    if (!categorySlug) {
      axios
        .get("/api/categories/getall")
        .then((res) => {
          let categories = res.data.map((category) => ({
            _id: category._id,
            categoryID: category.categoryID,
            name: transformToSlug(category.name),
            fullName: category.name,
            products: null,
          }));
          const apiPromises = categories.map(async (category) => {
            try {
              const response = await axios.get(
                `api/products/getFollowCategory/${category.name}`
              );
              return response.data.products;
            } catch (error) {
              console.error(
                `Lỗi khi gửi yêu cầu API cho ${category.name}: ${error.message}`
              );
              return null;
            }
          });
          Promise.all(apiPromises)
            .then((listProducts) => {
              categories.forEach((category, index) => {
                category.products = listProducts[index];
              });

              // categories = categories.filter(
              //   (category) => category.products.length >= 10
              // );
              setArrayCategories(categories);
              setShimmer(true);
            })
            .catch((error) => {
              console.error("Lỗi khi gửi các yêu cầu API:", error);
            });
        })
        .catch((e) => {});
    } else {
      axios
        .post(`/api/products/ProductCategoryWithPagination`, {
          categorySlug: categorySlug,
        })
        .then((res) => {
          setProducts(res.data.products);
          setCategory(res.data.category);
          setTotalPages(res.data.pages);
          setShimmer(true);
        })
        .catch((e) => {});
    }
  }, [categorySlug]);
  const handlePageChange = async (page) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentPage(page);
    setShimmer(false);
    axios
      .post("/api/products/ProductCategoryWithPagination", {
        categorySlug: categorySlug,
        page: page,
      })
      .then((response) => {
        console.log(response);
        setProducts(response.data.products);
        setTotalPages(response.data.pages);
        setShimmer(true);
      })
      .catch((error) => {
        setProducts([]);
        setTotalPages(0);
        setShimmer(true);
      });
  };
  return shimmer ? (
    <>
      <div className="category">
        <Helmet>
          <link rel="stylesheet" href="/css/page_search.css" />
        </Helmet>
        {categorySlug ? (
          <>
            {category ? (
              <>
                <Helmet>
                  <title>{category.name}</title>
                </Helmet>
                <div className="category-page-phone  dt-prd-detail-product-phones">
                  <div className="dt-prd-classify">
                    <Link to="/">
                      <img
                        src={"/img/home.png"}
                        alt=""
                        className="dt-prd-home"
                      />
                    </Link>
                    <img
                      src={"/img/chevron.png"}
                      alt=""
                      className="dt-prd-left-arrow"
                    />
                    <Link to={`/danh-muc`} className="dt-prd-category">
                      Danh mục sản phẩm
                    </Link>
                    <img
                      src={"/img/chevron.png"}
                      alt=""
                      className="dt-prd-left-arrow"
                    />

                    <Link
                      to={`/danh-muc/${categorySlug}`}
                      className="dt-prd-category dt-prd-active"
                    >
                      {category.name}
                    </Link>
                  </div>
                  <div className="home-row-product-phone">
                    {products.map((product, index) => (
                      <Link
                        to={
                          "/chi-tiet-san-pham/" +
                          transformToSlug(product.productName)
                        }
                        id="redirect-product-phone-1"
                      >
                        <div className="home-product-1">
                          {product.sellingPrice !== product.originalPrice
                            ? (() => {
                                const originalPrice = product.originalPrice;
                                const sellingPrice = product.sellingPrice;
                                const discountValue =
                                  originalPrice - sellingPrice;
                                const discountPercent =
                                  (discountValue / originalPrice) * 100;
                                return (
                                  <div className="home-discount">
                                    <div className="home-clos">
                                      {discountPercent.toFixed(0)}%
                                    </div>
                                    <div className="home-col-d">
                                      <div className="home-left" />
                                      <div className="home-right" />
                                    </div>
                                  </div>
                                );
                              })()
                            : null}

                          <img src={`/${product.urlImage}`} alt="" />
                          <h5 className="home-name-product">
                            {product.productName}
                          </h5>
                          <div className="home-price">
                            {product.sellingPrice !== product.originalPrice ? (
                              <>
                                <p className="home-origin-price">
                                  {product.originalPrice.toLocaleString(
                                    "vi-VN",
                                    {
                                      style: "currency",
                                      currency: "VND",
                                    }
                                  )}
                                </p>
                                <p className="home-offcial-price">
                                  {product.sellingPrice.toLocaleString(
                                    "vi-VN",
                                    {
                                      style: "currency",
                                      currency: "VND",
                                    }
                                  )}
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="home-offcial-price">
                                  {product.sellingPrice.toLocaleString(
                                    "vi-VN",
                                    {
                                      style: "currency",
                                      currency: "VND",
                                    }
                                  )}
                                </p>
                              </>
                            )}
                          </div>
                          <div className="home-star">
                            {Array.from({ length: 5 }, (_, index) =>
                              index < product.averageRating ? (
                                <span className="fa fa-star checked" />
                              ) : (
                                <span className="fa fa-star" />
                              )
                            )}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>

                  <div className="orh-header-row">
                    {/* Nút trang trước */}
                    {currentPage == 1 ? (
                      <a className="orh-slide-page orh-last-page">
                        <img src="/img/previous.png" alt="Previous" />
                      </a>
                    ) : (
                      <a
                        onClick={() => handlePageChange(currentPage - 1)}
                        className="orh-slide-page"
                      >
                        <img src="/img/previous.png" alt="Previous" />
                      </a>
                    )}

                    {/* Hiển thị các trang */}
                    {Array.from({ length: totalPages }, (_, index) => {
                      if (
                        totalPages <= 3 ||
                        index + 1 === 1 ||
                        index + 1 === totalPages ||
                        (index + 1 >= currentPage - 1 &&
                          index + 1 <= currentPage + 1)
                      ) {
                        return (
                          <a
                            key={index + 1}
                            className={`orh-slide-number ${
                              index + 1 === currentPage ? "active" : "clane"
                            }`}
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </a>
                        );
                      }

                      return null;
                    })}
                    {currentPage == totalPages ? (
                      <a className="orh-slide-page orh-last-page">
                        <img src="/img/next.png" alt="Next" />
                      </a>
                    ) : (
                      <a
                        className="orh-slide-page"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <img src="/img/next.png" alt="Next" />
                      </a>
                    )}
                  </div>
                </div>
                <div className="category-page">
                  <div className="category-classify">
                    <Link to="/" className="category-category">
                      <img
                        src="/img/home.png"
                        alt=""
                        className="category-home"
                      />
                    </Link>
                    <img
                      src="/img/chevron.png"
                      alt=""
                      className="category-left-arrow"
                    />
                    <Link to="/danh-muc" className="category-category">
                      Danh Mục Sản Phẩm
                    </Link>
                    <img
                      src="/img/chevron.png"
                      alt=""
                      className="category-left-arrow"
                    />
                    <Link
                      to={`/danh-muc/${categorySlug}`}
                      className="category-category category-active"
                    >
                      {category.name}
                    </Link>
                  </div>
                  <div className="flex-center">
                    <div className="home-row-product">
                      {products.map((product, index) => (
                        <Link
                          to={
                            "/chi-tiet-san-pham/" +
                            transformToSlug(product.productName)
                          }
                          id="redirect-product1"
                        >
                          <div className="home-product">
                            {product.sellingPrice !== product.originalPrice
                              ? (() => {
                                  const originalPrice = product.originalPrice;
                                  const sellingPrice = product.sellingPrice;
                                  const discountValue =
                                    originalPrice - sellingPrice;
                                  const discountPercent =
                                    (discountValue / originalPrice) * 100;
                                  return (
                                    <div className="home-discount">
                                      <div className="home-clos">
                                        {discountPercent.toFixed(0)}%
                                      </div>
                                      <div className="home-col-d">
                                        <div className="home-left" />
                                        <div className="home-right" />
                                      </div>
                                    </div>
                                  );
                                })()
                              : null}

                            <img src={`/${product.urlImage}`} alt="" />
                            <h5 className="home-name-product">
                              {product.productName}
                            </h5>
                            <div className="home-price">
                              {product.sellingPrice !==
                              product.originalPrice ? (
                                <>
                                  <p className="home-origin-price">
                                    {product.originalPrice.toLocaleString(
                                      "vi-VN",
                                      {
                                        style: "currency",
                                        currency: "VND",
                                      }
                                    )}
                                  </p>
                                  <p className="home-offcial-price">
                                    {product.sellingPrice.toLocaleString(
                                      "vi-VN",
                                      {
                                        style: "currency",
                                        currency: "VND",
                                      }
                                    )}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="home-offcial-price">
                                    {product.sellingPrice.toLocaleString(
                                      "vi-VN",
                                      {
                                        style: "currency",
                                        currency: "VND",
                                      }
                                    )}
                                  </p>
                                </>
                              )}
                            </div>
                            <div className="home-star">
                              {Array.from({ length: 5 }, (_, index) =>
                                index < product.averageRating ? (
                                  <span className="fa fa-star checked" />
                                ) : (
                                  <span className="fa fa-star" />
                                )
                              )}
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                  {totalPages > 1 ? (
                    <div className="header-row">
                      {/* Nút trang trước */}
                      {currentPage == 1 ? (
                        <a className="slide-page last-page">
                          <img src="/img/previous.png" alt="" />
                        </a>
                      ) : (
                        <a
                          onClick={() => handlePageChange(currentPage - 1)}
                          className="slide-page"
                        >
                          <img src="/img/previous.png" alt="Previous" />
                        </a>
                      )}

                      {/* Hiển thị các trang */}
                      {Array.from({ length: totalPages }, (_, index) => {
                        if (
                          totalPages <= 3 ||
                          index + 1 === 1 ||
                          index + 1 === totalPages ||
                          (index + 1 >= currentPage - 1 &&
                            index + 1 <= currentPage + 1)
                        ) {
                          return (
                            <a
                              key={index + 1}
                              className={`slide-number ${
                                index + 1 === currentPage ? "active" : "clane"
                              }`}
                              onClick={() => handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </a>
                          );
                        }

                        return null;
                      })}
                      {currentPage == totalPages ? (
                        <a className="slide-page last-page">
                          <img src="/img/next.png" alt="Next" />
                        </a>
                      ) : (
                        <a
                          className="slide-page"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <img src="/img/next.png" alt="Next" />
                        </a>
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="category-page-phone"></div>
              </>
            ) : null}
          </>
        ) : (
          <>
            <Helmet>
              <title>Danh Mục Sản Phẩm</title>
            </Helmet>
            <div className="category-page-phone">
              <div style={{ fontSize: "18px" }} className="home-black-category">
                Danh Mục Sản Phẩm
              </div>
              {ArrayCategories.length > 0
                ? ArrayCategories.map((category) => (
                    <>
                      <div className="home-header-main-phone">
                        <p className="home-title-header-main-phone">
                          {category.fullName}
                        </p>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/danh-muc/${category.name}`}
                          className="home-link-to-detail"
                        >
                          Xem tất cả {"►"}
                        </Link>
                      </div>
                      <Swiper
                        slidesPerView={2}
                        spaceBetween={0}
                        className="home-row-products-phone"
                      >
                        {category.products.map((product, index) => (
                          <SwiperSlide>
                            <Link
                              to={
                                "/chi-tiet-san-pham/" +
                                transformToSlug(product.productName)
                              }
                              id="redirect-product-phone"
                            >
                              <div className="home-product">
                                {product.sellingPrice !== product.originalPrice
                                  ? (() => {
                                      const originalPrice =
                                        product.originalPrice;
                                      const sellingPrice = product.sellingPrice;
                                      const discountValue =
                                        originalPrice - sellingPrice;
                                      const discountPercent =
                                        (discountValue / originalPrice) * 100;
                                      return (
                                        <div className="home-discount">
                                          <div className="home-clos">
                                            {discountPercent.toFixed(0)}%
                                          </div>
                                          <div className="home-col-d">
                                            <div className="home-left" />
                                            <div className="home-right" />
                                          </div>
                                        </div>
                                      );
                                    })()
                                  : null}

                                <img src={`/${product.urlImage}`} alt="" />
                                <h5 className="home-name-product">
                                  {product.productName}
                                </h5>
                                <div className="home-price">
                                  {product.sellingPrice !==
                                  product.originalPrice ? (
                                    <>
                                      <p className="home-origin-price">
                                        {product.originalPrice.toLocaleString(
                                          "vi-VN",
                                          {
                                            style: "currency",
                                            currency: "VND",
                                          }
                                        )}
                                      </p>
                                      <p className="home-offcial-price">
                                        {product.sellingPrice.toLocaleString(
                                          "vi-VN",
                                          {
                                            style: "currency",
                                            currency: "VND",
                                          }
                                        )}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p className="home-offcial-price">
                                        {product.sellingPrice.toLocaleString(
                                          "vi-VN",
                                          {
                                            style: "currency",
                                            currency: "VND",
                                          }
                                        )}
                                      </p>
                                    </>
                                  )}
                                </div>
                                <div className="home-star">
                                  {Array.from({ length: 5 }, (_, index) =>
                                    index < product.averageRating ? (
                                      <span className="fa fa-star checked" />
                                    ) : (
                                      <span className="fa fa-star" />
                                    )
                                  )}
                                </div>
                              </div>
                            </Link>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </>
                  ))
                : null}
            </div>
            <div className="category-page">
              <div className="ctg-row-center">Danh Mục Sản Phẩm</div>
              {ArrayCategories.length > 0
                ? ArrayCategories.map((category) => (
                    <>
                      <div className="home-product-head-bar">
                        <h3 className="home-header">{category.fullName}</h3>
                        <Link
                          to={`/danh-muc/${category.name}`}
                          className="home-redirect"
                        >
                          Xem tất cả
                          <img
                            src="/img/right-arrows.png"
                            alt=""
                            style={{ width: "30px", height: "30px" }}
                          />
                        </Link>
                      </div>
                      <div className="flex-center">
                        <div className="home-row-product">
                          {category.products.map((product, index) => (
                            <Link
                              to={
                                "/chi-tiet-san-pham/" +
                                transformToSlug(product.productName)
                              }
                              id="redirect-product1"
                            >
                              <div className="home-product">
                                {product.sellingPrice !== product.originalPrice
                                  ? (() => {
                                      const originalPrice =
                                        product.originalPrice;
                                      const sellingPrice = product.sellingPrice;
                                      const discountValue =
                                        originalPrice - sellingPrice;
                                      const discountPercent =
                                        (discountValue / originalPrice) * 100;
                                      return (
                                        <div className="home-discount">
                                          <div className="home-clos">
                                            {discountPercent.toFixed(0)}%
                                          </div>
                                          <div className="home-col-d">
                                            <div className="home-left" />
                                            <div className="home-right" />
                                          </div>
                                        </div>
                                      );
                                    })()
                                  : null}

                                <img src={product.urlImage} alt="" />
                                <h5 className="home-name-product">
                                  {product.productName}
                                </h5>
                                <div className="home-price">
                                  {product.sellingPrice !==
                                  product.originalPrice ? (
                                    <>
                                      <p className="home-origin-price">
                                        {product.originalPrice.toLocaleString(
                                          "vi-VN",
                                          {
                                            style: "currency",
                                            currency: "VND",
                                          }
                                        )}
                                      </p>
                                      <p className="home-offcial-price">
                                        {product.sellingPrice.toLocaleString(
                                          "vi-VN",
                                          {
                                            style: "currency",
                                            currency: "VND",
                                          }
                                        )}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p className="home-offcial-price">
                                        {product.sellingPrice.toLocaleString(
                                          "vi-VN",
                                          {
                                            style: "currency",
                                            currency: "VND",
                                          }
                                        )}
                                      </p>
                                    </>
                                  )}
                                </div>
                                <div className="home-star">
                                  {Array.from({ length: 5 }, (_, index) =>
                                    index < product.averageRating ? (
                                      <span className="fa fa-star checked" />
                                    ) : (
                                      <span className="fa fa-star" />
                                    )
                                  )}
                                </div>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </>
                  ))
                : null}
            </div>
          </>
        )}
        <div className="category-page">
          <div className="home-trailer">
            <h1>HỆ THỐNG PHÂN PHỐI DỤNG CỤ Y TẾ CHÍNH HÃNG</h1>
            <h1>LƯU GIA</h1>
          </div>
          <div className="home-header-brain">Thương hiệu nổi bật</div>
          <div className="home-brain-icon">
            <img
              src="/img/brand_icon/omron.png"
              alt=""
              className="home-img-brand-icon"
            />
            <img
              src="/img/brand_icon/beurer.png"
              alt=""
              className="home-img-brand-icon"
            />
            <img
              src="/img/brand_icon/microlife.png"
              alt=""
              className="home-img-brand-icon"
            />
            <img
              src="/img/brand_icon/omron.png"
              alt=""
              className="home-img-brand-icon"
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>Danh Mục Sản Phẩm</title>
      </Helmet>
      <div className="shimmer-body">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            padding: "10px",
          }}
        >
          <ShimmerThumbnail className={"shimmer-header-ctg"} height={45} />
        </div>
        <div className="shimmer-row-prd-ctg">
          <div className="shimmer-header-name">
            <ShimmerThumbnail className={"shimmer-header-ctg"} height={30} />
          </div>
          <div className="shimmer-row">
            <div className="shimmer-loader-prd">
              <ShimmerThumbnail className={"shimmer-prd-img"} height={250} />
              <ShimmerThumbnail className={"shimmer-prd-title"} height={25} />
              <ShimmerThumbnail className={"shimmer-prd-content"} height={20} />
              <ShimmerThumbnail className={"shimmer-buy-bt"} height={30} />
            </div>
            <div className="shimmer-loader-prd">
              <ShimmerThumbnail className={"shimmer-prd-img"} height={250} />
              <ShimmerThumbnail className={"shimmer-prd-title"} height={25} />
              <ShimmerThumbnail className={"shimmer-prd-content"} height={20} />
              <ShimmerThumbnail className={"shimmer-buy-bt"} height={30} />
            </div>{" "}
            <div className="shimmer-loader-prd">
              <ShimmerThumbnail className={"shimmer-prd-img"} height={250} />
              <ShimmerThumbnail className={"shimmer-prd-title"} height={25} />
              <ShimmerThumbnail className={"shimmer-prd-content"} height={20} />
              <ShimmerThumbnail className={"shimmer-buy-bt"} height={30} />
            </div>{" "}
            <div className="shimmer-loader-prd">
              <ShimmerThumbnail className={"shimmer-prd-img"} height={250} />
              <ShimmerThumbnail className={"shimmer-prd-title"} height={25} />
              <ShimmerThumbnail className={"shimmer-prd-content"} height={20} />
              <ShimmerThumbnail className={"shimmer-buy-bt"} height={30} />
            </div>{" "}
            <div className="shimmer-loader-prd">
              <ShimmerThumbnail className={"shimmer-prd-img"} height={250} />
              <ShimmerThumbnail className={"shimmer-prd-title"} height={25} />
              <ShimmerThumbnail className={"shimmer-prd-content"} height={20} />
              <ShimmerThumbnail className={"shimmer-buy-bt"} height={30} />
            </div>{" "}
            <div className="shimmer-loader-prd">
              <ShimmerThumbnail className={"shimmer-prd-img"} height={250} />
              <ShimmerThumbnail className={"shimmer-prd-title"} height={25} />
              <ShimmerThumbnail className={"shimmer-prd-content"} height={20} />
              <ShimmerThumbnail className={"shimmer-buy-bt"} height={30} />
            </div>{" "}
            <div className="shimmer-loader-prd">
              <ShimmerThumbnail className={"shimmer-prd-img"} height={250} />
              <ShimmerThumbnail className={"shimmer-prd-title"} height={25} />
              <ShimmerThumbnail className={"shimmer-prd-content"} height={20} />
              <ShimmerThumbnail className={"shimmer-buy-bt"} height={30} />
            </div>{" "}
            <div className="shimmer-loader-prd">
              <ShimmerThumbnail className={"shimmer-prd-img"} height={250} />
              <ShimmerThumbnail className={"shimmer-prd-title"} height={25} />
              <ShimmerThumbnail className={"shimmer-prd-content"} height={20} />
              <ShimmerThumbnail className={"shimmer-buy-bt"} height={30} />
            </div>{" "}
            <div className="shimmer-loader-prd">
              <ShimmerThumbnail className={"shimmer-prd-img"} height={250} />
              <ShimmerThumbnail className={"shimmer-prd-title"} height={25} />
              <ShimmerThumbnail className={"shimmer-prd-content"} height={20} />
              <ShimmerThumbnail className={"shimmer-buy-bt"} height={30} />
            </div>{" "}
            <div className="shimmer-loader-prd">
              <ShimmerThumbnail className={"shimmer-prd-img"} height={250} />
              <ShimmerThumbnail className={"shimmer-prd-title"} height={25} />
              <ShimmerThumbnail className={"shimmer-prd-content"} height={20} />
              <ShimmerThumbnail className={"shimmer-buy-bt"} height={30} />
            </div>{" "}
            <div className="shimmer-loader-prd">
              <ShimmerThumbnail className={"shimmer-prd-img"} height={250} />
              <ShimmerThumbnail className={"shimmer-prd-title"} height={25} />
              <ShimmerThumbnail className={"shimmer-prd-content"} height={20} />
              <ShimmerThumbnail className={"shimmer-buy-bt"} height={30} />
            </div>{" "}
            <div className="shimmer-loader-prd">
              <ShimmerThumbnail className={"shimmer-prd-img"} height={250} />
              <ShimmerThumbnail className={"shimmer-prd-title"} height={25} />
              <ShimmerThumbnail className={"shimmer-prd-content"} height={20} />
              <ShimmerThumbnail className={"shimmer-buy-bt"} height={30} />
            </div>
          </div>
        </div>
      </div>
      <div className="shimmer-body-phone">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            padding: "10px",
          }}
        >
          <ShimmerThumbnail
            className={"phone-shimmer-header-ctg"}
            height={30}
          />
        </div>
        <div className="phone-shimmer-row-prd-ctg">
          <div className="phone-shimmer-header-name">
            <ShimmerThumbnail
              className={"phone-shimmer-header-ctg"}
              height={30}
            />
          </div>
          <div className="phone-shimmer-row">
            <div className="phone-shimmer-loader-prd">
              <ShimmerThumbnail
                className={"phone-shimmer-prd-img"}
                height={120}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-title"}
                height={15}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-content"}
                height={10}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-buy-bt"}
                height={15}
              />
            </div>
            <div className="phone-shimmer-loader-prd">
              <ShimmerThumbnail
                className={"phone-shimmer-prd-img"}
                height={120}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-title"}
                height={15}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-content"}
                height={10}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-buy-bt"}
                height={15}
              />
            </div>{" "}
            <div className="phone-shimmer-loader-prd">
              <ShimmerThumbnail
                className={"phone-shimmer-prd-img"}
                height={120}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-title"}
                height={15}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-content"}
                height={10}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-buy-bt"}
                height={15}
              />
            </div>{" "}
            <div className="phone-shimmer-loader-prd">
              <ShimmerThumbnail
                className={"phone-shimmer-prd-img"}
                height={120}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-title"}
                height={15}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-content"}
                height={10}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-buy-bt"}
                height={15}
              />
            </div>{" "}
            <div className="phone-shimmer-loader-prd">
              <ShimmerThumbnail
                className={"phone-shimmer-prd-img"}
                height={120}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-title"}
                height={15}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-content"}
                height={10}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-buy-bt"}
                height={15}
              />
            </div>{" "}
            <div className="phone-shimmer-loader-prd">
              <ShimmerThumbnail
                className={"phone-shimmer-prd-img"}
                height={120}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-title"}
                height={15}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-content"}
                height={10}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-buy-bt"}
                height={15}
              />
            </div>{" "}
            <div className="phone-shimmer-loader-prd">
              <ShimmerThumbnail
                className={"phone-shimmer-prd-img"}
                height={120}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-title"}
                height={15}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-content"}
                height={10}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-buy-bt"}
                height={15}
              />
            </div>{" "}
            <div className="phone-shimmer-loader-prd">
              <ShimmerThumbnail
                className={"phone-shimmer-prd-img"}
                height={120}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-title"}
                height={15}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-content"}
                height={10}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-buy-bt"}
                height={15}
              />
            </div>{" "}
            <div className="phone-shimmer-loader-prd">
              <ShimmerThumbnail
                className={"phone-shimmer-prd-img"}
                height={120}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-title"}
                height={15}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-content"}
                height={10}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-buy-bt"}
                height={15}
              />
            </div>{" "}
            <div className="phone-shimmer-loader-prd">
              <ShimmerThumbnail
                className={"phone-shimmer-prd-img"}
                height={120}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-title"}
                height={15}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-content"}
                height={10}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-buy-bt"}
                height={15}
              />
            </div>{" "}
            <div className="phone-shimmer-loader-prd">
              <ShimmerThumbnail
                className={"phone-shimmer-prd-img"}
                height={120}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-title"}
                height={15}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-content"}
                height={10}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-buy-bt"}
                height={15}
              />
            </div>{" "}
            <div className="phone-shimmer-loader-prd">
              <ShimmerThumbnail
                className={"phone-shimmer-prd-img"}
                height={120}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-title"}
                height={15}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-prd-content"}
                height={10}
              />
              <ShimmerThumbnail
                className={"phone-shimmer-buy-bt"}
                height={15}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
