import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { faL } from "@fortawesome/free-solid-svg-icons";
function Cart(props) {
  const updateCartItemCount = (newCount) => {
    props.updateCartItemCount(newCount);
  };
  const [loading, setLoading] = useState(false);
  const URlSERVER = "/";
  const nav = useNavigate();
  const token = Cookies.get("accessToken");
  const [haveProduct, setHaveProduct] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const calculateTotalPrice = () => {
    if (products.length == 0) {
      setHaveProduct(false);
    }
    setTotalAmount(
      products.reduce((total, product) => {
        return total + product.cartItem.quantity * product.product.sellingPrice;
      }, 0)
    );
  };
  useEffect(() => {
    if (token) {
      axios
        .get("/api/cart/get-cart", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setHaveProduct(true);
          const productList = response.data.combinedItems;
          setProducts(productList);

          if (productList && productList.length > 0) {
            // Tính tổng tiền
            const cartTotal = productList.reduce((total, item) => {
              const itemTotal =
                item.product.sellingPrice * item.cartItem.quantity;
              return total + itemTotal;
            }, 0);

            setTotalAmount(cartTotal);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const productIdsInLocalStorage = localStorage.getItem("productIds");
      const listProductIds = JSON.parse(productIdsInLocalStorage)
        ? JSON.parse(productIdsInLocalStorage).map(
            (product) => product.productId
          )
        : null;
      axios
        .post(
          "/api/products/get-list-product-in-cart",
          { productIds: listProductIds ? listProductIds : null },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const products = response.data;
          const combinedItems = JSON.parse(productIdsInLocalStorage).map(
            (productIdInLocalStorage) => {
              const matchingProduct = products.find(
                (product) => product.sku === productIdInLocalStorage.productId
              );

              if (matchingProduct) {
                return {
                  cartItem: {
                    productId: productIdInLocalStorage.productId,
                    quantity: productIdInLocalStorage.quantity,
                  },
                  product: matchingProduct,
                };
              }
            }
          );
          setHaveProduct(true);
          const productList = combinedItems;
          setProducts(productList);

          if (productList && productList.length > 0) {
            // Tính tổng tiền
            const cartTotal = productList.reduce((total, item) => {
              const itemTotal =
                item.product.sellingPrice * item.cartItem.quantity;
              return total + itemTotal;
            }, 0);

            setTotalAmount(cartTotal);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  const handleIncreaseQuantity = (productId, sku, cartId) => {
    const productIdsInLocalStorage = localStorage.getItem("productIds");
    if (token) {
      const updatedProductsCopy = [...products];
      const productToUpdate = updatedProductsCopy.find(
        (product) => product.product._id === productId
      );
      if (productToUpdate && productToUpdate.cartItem.quantity < 10) {
        setLoading(true);
        axios
          .put(
            `/api/cart/update-cart-item/${cartId}`,
            { quantity: productToUpdate.cartItem.quantity + 1 },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resoponse) => {
            setLoading(false);
            productToUpdate.cartItem.quantity += 1;
            setProducts(updatedProductsCopy);
            calculateTotalPrice();
          })
          .catch((e) => {
            setLoading(false);
            console.log(e);
          });
      }
    } else {
      const updatedProductsCopy = [...products];
      const productToUpdate = updatedProductsCopy.find(
        (product) => product.product._id === productId
      );
      if (productToUpdate && productToUpdate.cartItem.quantity < 10) {
        productToUpdate.cartItem.quantity += 1;
        setProducts(updatedProductsCopy);
        const updatedProductIds = JSON.parse(productIdsInLocalStorage).map(
          (item) => {
            if (item.productId === sku) {
              return { productId: sku, quantity: item.quantity + 1 };
            }
            return item;
          }
        );
        localStorage.setItem("productIds", JSON.stringify(updatedProductIds));
        calculateTotalPrice();
      }
    }
  };

  const handleDecreaseQuantity = (productId, sku, cartId) => {
    const productIdsInLocalStorage = localStorage.getItem("productIds");
    if (token) {
      const updatedProductsCopy = [...products];
      const productToUpdate = updatedProductsCopy.find(
        (product) => product.product._id === productId
      );

      if (productToUpdate && productToUpdate.cartItem.quantity > 1) {
        setLoading(true);
        axios
          .put(
            `/api/cart/update-cart-item/${cartId}`,
            { quantity: productToUpdate.cartItem.quantity - 1 },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resoponse) => {
            setLoading(false);
            productToUpdate.cartItem.quantity -= 1;
            setProducts(updatedProductsCopy);
            calculateTotalPrice();
          })
          .catch((e) => {
            setLoading(false);
            console.log(e);
          });
      }
    } else {
      const updatedProductsCopy = [...products];
      const productToUpdate = updatedProductsCopy.find(
        (product) => product.product._id === productId
      );

      if (productToUpdate && productToUpdate.cartItem.quantity > 1) {
        productToUpdate.cartItem.quantity -= 1;
        setProducts(updatedProductsCopy);
        const updatedProductIds = JSON.parse(productIdsInLocalStorage).map(
          (item) => {
            if (item.productId === sku) {
              return { productId: sku, quantity: item.quantity - 1 };
            }
            return item;
          }
        );
        localStorage.setItem("productIds", JSON.stringify(updatedProductIds));
        calculateTotalPrice();
      }
    }
  };
  const deleteProduct = (productId, sku, cartId) => {
    if (token) {
      setLoading(true);
      axios
        .delete(`/api/cart/delete-cart-item/${cartId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((resoponse) => {
          setLoading(false);
          const updatedProductsCopy = [...products];
          const productToUpdate = updatedProductsCopy.filter(
            (product) => product.product._id !== productId
          );
          updateCartItemCount(productToUpdate.length);
          const updatedProducts = products.filter(
            (product) => product.product._id !== productId
          );
          setProducts(updatedProducts);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    } else {
      const productIdsInLocalStorage = JSON.parse(
        localStorage.getItem("productIds")
      );
      const updatedProductIds = productIdsInLocalStorage.filter(
        (item) => item.productId !== sku
      );
      localStorage.setItem("productIds", JSON.stringify(updatedProductIds));
      updateCartItemCount(updatedProductIds.length);
      // Xóa sản phẩm khỏi state products
      const updatedProducts = products.filter(
        (product) => product.product._id !== productId
      );
      setProducts(updatedProducts);
    }
  };
  useEffect(() => {
    calculateTotalPrice();
    if (products.length == 0) {
      setHaveProduct(false);
    }
  }, [products]);
  return (
    <>
      <Helmet>
        <title>Lưu Gia - Giỏ Hàng</title>
      </Helmet>
      <div
        className="cart-lds-ring"
        style={{ display: loading ? "flex" : "none" }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="cart-cart-1">
        <div className="cart-center">Giỏ hàng của bạn</div>
        <div className="cart-main-cart">
          <div className="cart-left">
            <div className="cart-header">
              <div className="cart-name">
                <p>Sản Phẩm</p>
              </div>
              <div className="cart-price">Giá</div>
              <div className="cart-quantity">Số Lượng</div>
              <div className="cart-total">Tổng Tiền</div>
            </div>
            <div className="cart-carts">
              {haveProduct ? (
                <>
                  {products.map((product) => (
                    <div className="cart-row-product" key={product.product._id}>
                      <div className="cart-name">
                        <img
                          src={product.product.urlImage}
                          alt={product.product.productName}
                        />
                        <p>{product.product.productName}</p>
                      </div>
                      <div className="cart-price">
                        {product.product.sellingPrice.toLocaleString("vi-VN", {
                          style: "currency",
                          currency: "VND",
                        })}
                      </div>
                      <div className="cart-quantity">
                        <div className="cart-frame-quatity">
                          <div
                            className="cart-sign"
                            onClick={() =>
                              handleDecreaseQuantity(
                                product.product._id,
                                product.product.sku,
                                product.cartItem._id || ""
                              )
                            }
                          >
                            <div className="cart-minus" />
                          </div>
                          <div className="cart-quatity">
                            {product.cartItem.quantity}
                          </div>
                          <div
                            className="cart-sign"
                            onClick={() =>
                              handleIncreaseQuantity(
                                product.product._id,
                                product.product.sku,
                                product.cartItem._id || ""
                              )
                            }
                          >
                            <div className="cart-plus-1" />
                            <div className="cart-plus-2" />
                          </div>
                        </div>
                      </div>
                      <div className="cart-total">
                        {(
                          product.product.sellingPrice *
                          product.cartItem.quantity
                        ).toLocaleString("vi-VN", {
                          style: "currency",
                          currency: "VND",
                        })}
                      </div>
                      <div
                        className="cart-cancel"
                        onClick={() => {
                          deleteProduct(
                            product.product._id,
                            product.product.sku,
                            product.cartItem._id || ""
                          );
                        }}
                      >
                        <div className="cart-line1" />
                        <div className="cart-line2" />
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="cart-no-items">
                  <span className="cart-my-3" style={{ textAlign: "center" }}>
                    Giỏ hàng của bạn đang trống. Hãy chọn thêm sản phẩm để mua
                    sắm nhé.
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="cart-right">
            <div className="cart-header">Thông Tin Giỏ Hàng</div>
            <div className="cart-row-total-temporary">
              <div className="cart-left-1">Tổng tiền (tạm tính):</div>
              <div className="cart-right-1">
                {totalAmount.toLocaleString("vi-VN", {
                  style: "currency",
                  currency: "VND",
                })}
              </div>
            </div>
            <div className="cart-row-total">
              <div className="cart-left-1">Tổng tiền:</div>
              <div className="cart-right-1">
                {totalAmount.toLocaleString("vi-VN", {
                  style: "currency",
                  currency: "VND",
                })}
              </div>
            </div>
            {haveProduct ? (
              <div
                className="cart-paynow"
                onClick={() => {
                  nav("/thanh-toan");
                }}
              >
                TIẾN HÀNH THANH TOÁN
              </div>
            ) : (
              <div className="cart-notpaynow">TIẾN HÀNH THANH TOÁN</div>
            )}
          </div>
        </div>
      </div>
      <div
        className="cart-cart-phone"
        style={{ justifyContent: haveProduct ? "flex-start" : "center" }}
      >
        {haveProduct ? (
          <>
            <div className="cart-title-cart">Giỏ hàng của bạn</div>
            <div className="cart-title-list-products">Danh sách sản phẩm</div>
            <div className="cart-list-products">
              {products.map((product) => (
                <div className="cart-component-product">
                  <div className="cart-frame-img">
                    <img
                      src={product.product.urlImage}
                      alt={product.product.productName}
                    />
                  </div>
                  <div className="cart-frame-name-price-quatity">
                    <p className="cart-name">{product.product.productName}</p>
                    <p className="cart-price">
                      <strong style={{ color: "black", fontWeight: "500" }}>
                        Giá:{" "}
                      </strong>
                      {product.product.sellingPrice.toLocaleString("vi-VN", {
                        style: "currency",
                        currency: "VND",
                      })}
                    </p>
                    <div className="cart-quantity">
                      <div className="cart-frame-quatity">
                        <div
                          className="cart-sign"
                          onClick={() =>
                            handleDecreaseQuantity(
                              product.product._id,
                              product.product.sku,
                              product.cartItem._id || ""
                            )
                          }
                        >
                          <div className="cart-minus" />
                        </div>
                        <div className="cart-quatity">
                          {product.cartItem.quantity}
                        </div>
                        <div
                          className="cart-sign"
                          onClick={() =>
                            handleIncreaseQuantity(
                              product.product._id,
                              product.product.sku,
                              product.cartItem._id || ""
                            )
                          }
                        >
                          <div className="cart-plus-1" />
                          <div className="cart-plus-2" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="cart-cancel"
                    onClick={() => {
                      deleteProduct(
                        product.product._id,
                        product.product.sku,
                        product.cartItem._id || ""
                      );
                    }}
                  >
                    <div className="cart-line1" />
                    <div className="cart-line2" />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="cart-no-items">
            <span className="cart-my-3" style={{ textAlign: "center" }}>
              Giỏ hàng của bạn đang trống. Hãy chọn thêm sản phẩm để mua sắm
              nhé.
            </span>
          </div>
        )}
      </div>
      {haveProduct ? (
        <div className="cart-total-pay">
          <div className="cart-row-total">
            <p className="cart-text">Tổng tiền:</p>{" "}
            <p className="cart-price">
              {totalAmount.toLocaleString("vi-VN", {
                style: "currency",
                currency: "VND",
              })}
            </p>
          </div>
          <div
            onClick={() => {
              nav("/thanh-toan");
            }}
            className="cart-pay"
          >
            Thanh Toán
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Cart;
