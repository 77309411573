import React from "react";

function PaymentPolicy() {
  return (
    <div className="frame-content-policy">
      <h4>CÔNG TY CHÚNG TÔI ÁP DỤNG CÁC HÌNH THỨC THANH TOÁN SAU:</h4>

      <h5>1/ Thanh toán trực tiếp tại cửa hàng</h5>
      <p>Bằng tiền mặt VNĐ hoặc thanh toán bằng chuyển khoản.</p>

      <h5>2/ Đặt cọc và thanh toán khi giao hàng trong nội tỉnh</h5>
      <p>
        Nếu Quí khách đặt cọc 1 phần tiền và giao hàng trong nội tỉnh, Quý Khách
        vui lòng để lại thông tin cụ thể và chính xác địa chỉ, số điện thoại của
        người nhận. Chúng tôi sẽ giao hàng và thu phần tiền còn lại.
      </p>

      <h5>3/ Thanh toán qua chành xe thu hộ hoặc dịch vụ giao nhận hàng hóa</h5>
      <p>
        Khi Chúng tôi nhận được tiền của Quý khách gửi chành xe, bưu điện hoặc
        thông qua công ty giao nhận hàng hóa. Chúng tôi luôn đóng gói hàng hóa
        cẩn thận, ghi rõ tên người gửi, người nhận, kiện hàng gửi được yêu cần
        nhà xe kiểm tra kỹ – vận chuyển cẩn thận, có biên lai hay giấy tờ ký
        nhận hàng giúp cho kiện hàng không thất lạc.
      </p>

      <h5>4/ Thanh toán bằng hình thức chuyển khoản cá nhân</h5>
      <p>Tên người nhận: NGUYỄN THỊ KIM THOA</p>
      <p>Số tài khoản: 3399686868 – NGÂN HÀNG ACB</p>
      <p>
        Nếu quý khách muốn chắc chắn rằng, Tên người thụ hưởng, số tài khoản,
        tên ngân hàng mà quý khách cần chuyển, có thể kiểm tra lại bằng cách
        liên lạc lại với chúng tôi qua điện thoại hoặc e-mail đã ghi rõ trong
        phần liên hệ.
      </p>

      <p>Chân thành cảm ơn quý khách hàng!</p>
    </div>
  );
}

export default PaymentPolicy;
