import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
function Footer() {
  const [todayVisits, setTodayVisits] = useState(0);
  const [totalVisits, setTotalVisits] = useState(0);
  useEffect(() => {
    // Gọi API để lấy số lượt truy cập hôm nay
    axios
      .get("/api/visits/today")
      .then((response) => {
        setTodayVisits(response.data.today);
        axios
          .get("/api/visits/total")
          .then((response) => {
            setTotalVisits(response.data.total);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <>
      <footer className="ft-footer">
        <div className="ft-col-1">
          <img src={process.env.PUBLIC_URL + "/img/logo-footer.png"} alt="" />
          <p className="ft-text-1">
            Hệ thống phân phối dụng cụ y khoa chính hãng
          </p>
          <div className="ft-branch">
            <p className="ft-address">
              CN1: Số 164 Thành Thái, P.12, Quận 10, TP.Hồ Chí Minh
            </p>
            <p className="ft-hint">(Gần viện tim TP.Hồ Chí Minh)</p>
            <p className="ft-hotline">Hotline: 0933.875.008</p>
          </div>
          <div className="ft-branch">
            <p className="ft-address">
              CN2: 198 Tô Hiến Thành, P.15, Quận 10, TP.Hồ Chí Minh
            </p>
            <p className="ft-hint">(Đầu chợ sỉ thuốc Sài Gòn)</p>
            <p className="ft-hotline">Hotline: 0933.533.058</p>
          </div>
          <div className="ft-branch">
            <p className="ft-address">
              CN3: Số 1 Đoàn Hoàn Minh, P.6, TP.Bến Tre
            </p>
            <p className="ft-hint">(Cạnh bệnh viện Minh Đức)</p>
            <p className="ft-hotline">Hotline: 0933.363.531</p>
          </div>
        </div>
        <div className="ft-col-1">
          <p className="ft-text-2">Chính sách và qui định:</p>
          <div className="ft-link">
            <Link to="/chinh-sach-van-chuyen">Chính sách vận chuyển</Link>
            <Link to="/chinh-sach-doi-hoan-tien">Chính sách đổi/hoàn tiền</Link>
            <Link to="/chinh-sach-bao-mat-thong-tin">
              Chính sách bảo mật thông tin
            </Link>
            <Link to="/chinh-sach-bao-hanh">Chính sách bảo hành</Link>
            <Link to="/chinh-sach-thanh-toan">Chính sách thanh toán</Link>
          </div>
        </div>
        <div className="ft-col-1">
          <p className="ft-text-2">Giới Thiệu</p>
          <div className="ft-link">
            <Link to="/">Trang chủ</Link>
            <Link to="/danh-muc">Danh mục sản phẩm</Link>
            <Link to="/he-thong-cua-hang">Hệ thống cửa hàng</Link>
          </div>
          <Link  target="_blank" to="http://online.gov.vn/Home/WebDetails/113207">
            <img src="./img/da-thong-bao-bo-cong-thuong.png" alt="" />
          </Link>
          {/* <Link target="_blank" to="/">
            <img src="./img/da-dang-ky-bo-cong-thuong.png" alt="" />
          </Link> */}
        </div>
        <div className="ft-col-1">
          <p className="ft-text-2">PKD</p>
          <div className="ft-hotline">
            <p style={{ marginRight: "10px" }}>0907.533.288</p>
            <p>0938.478.918</p>
          </div>
          <div className="ft-hotline">
            <p style={{ marginRight: "10px" }}>0933.206.198</p>
            <p>0903.603.198</p>
          </div>
          <p className="ft-text-2">Kết nối với chúng tôi</p>
          <div className="ft-icon-social">
            <Link target="_blank"  to="https://www.facebook.com/profile.php?id=100075532252255">
              <img
                src={process.env.PUBLIC_URL + "/img/icon_social/facebook.png"}
                alt=""
              />
            </Link>
            <Link target="_blank" to="https://zalo.me/84907533288">
              <img
                src={process.env.PUBLIC_URL + "/img/icon_social/zalo.png"}
                alt=""
              />
            </Link>
            <Link target="_blank" to="https://www.youtube.com/@dcykluugia">
              <img
                src={process.env.PUBLIC_URL + "/img/icon_social/youtube.png"}
                alt=""
              />
            </Link>
          </div>
          <p className="ft-text-2">Lượt truy cập</p>
          <p style={{ fontSize: "15px", fontWeight: "400" }}>
            Hôm nay: {todayVisits}
          </p>
          <p style={{ fontSize: "15px", fontWeight: "400" }}>
            Tổng: {totalVisits}
          </p>
        </div>
        <iframe
          title="Y khoa lưu gia"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244.9631609884674!2d106.66783294070213!3d10.779832768754062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752eda79056eab%3A0xf051ed1857c6e1bd!2zcDE1LCAxOTggVMO0IEhp4bq_biBUaMOgbmgsIFBoxrDhu51uZyAxNSwgUXXhuq1uIDEwLCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmgsIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1663819427374!5m2!1svi!2s"
          width={300}
          height={250}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </footer>
      <footer className="ft-footer-phone">
        <div className="ft-title-row">
          <p className="ft-head">Hệ thống cửa hàng:</p>
        </div>
        <div className="ft-branch">
          <p className="ft-address">
            CN1: Số 164 Thành Thái, P.12, Quận 10, TP.Hồ Chí Minh
          </p>
          <p className="ft-hint">(Gần viện tim TP.Hồ Chí Minh)</p>
          <p className="ft-hotline">Hotline: 0933.875.008</p>
        </div>
        <div className="ft-branch">
          <p className="ft-address">
            CN2: 198 Tô Hiến Thành, P.15, Quận 10, TP.Hồ Chí Minh
          </p>
          <p className="ft-hint">(Đầu chợ sỉ thuốc Sài Gòn)</p>
          <p className="ft-hotline">Hotline: 0933.533.058</p>
        </div>
        <div className="ft-branch">
          <p className="ft-address">
            CN3: Số 1 Đoàn Hoàn Minh, P.6, TP.Bến Tre
          </p>
          <p className="ft-hint">(Cạnh bệnh viện Minh Đức)</p>
          <p className="ft-hotline">Hotline: 0933.363.531</p>
        </div>
        <div className="ft-title-row">
          <p className="ft-head">Chính sách và qui định:</p>
        </div>
        <div className="ft-branch">
          <Link
            style={{
              textDecoration: "none",
              fontSize: "13px",
              color: "#1a1a1a",
              marginBottom: "5px",
            }}
            to="/chinh-sach-van-chuyen"
          >
            Chính sách vận chuyển
          </Link>
          <Link
            style={{
              textDecoration: "none",
              fontSize: "13px",
              color: "#1a1a1a",
              marginBottom: "5px",
            }}
            to="/chinh-sach-doi-hoan-tien"
          >
            Chính sách đổi/hoàn tiền
          </Link>
          <Link
            style={{
              textDecoration: "none",
              fontSize: "13px",
              color: "#1a1a1a",
              marginBottom: "5px",
            }}
            to="/chinh-sach-bao-mat-thong-tin"
          >
            Chính sách bảo mật thông tin
          </Link>
          <Link
            style={{
              textDecoration: "none",
              fontSize: "13px",
              color: "#1a1a1a",
              marginBottom: "5px",
            }}
            to="/chinh-sach-bao-hanh"
          >
            Chính sách bảo hành
          </Link>
          <Link
            style={{
              textDecoration: "none",
              fontSize: "13px",
              color: "#1a1a1a",
              marginBottom: "5px",
            }}
            to="/chinh-sach-thanh-toan"
          >
            Chính sách thanh toán
          </Link>
        </div>
        <div className="ft-title-row">
          <p className="ft-head">Giới Thiệu:</p>
        </div>
        <div className="ft-branch">
          <Link
            style={{
              textDecoration: "none",
              fontSize: "13px",
              color: "#1a1a1a",
              marginBottom: "5px",
            }}
            to="/"
          >
            Trang chủ
          </Link>
          <Link
            style={{
              textDecoration: "none",
              fontSize: "13px",
              color: "#1a1a1a",
              marginBottom: "5px",
            }}
            to="/danh-muc"
          >
            Danh mục sản phẩm
          </Link>
          <Link
            style={{
              textDecoration: "none",
              fontSize: "13px",
              color: "#1a1a1a",
              marginBottom: "5px",
            }}
            to="/he-thong-cua-hang"
          >
            Hệ thống cửa hàng
          </Link>
        </div>
        <div className="ft-title-row">
          <p className="ft-head">PKD:</p>
        </div>
        <div className="ft-hotline">
          <p className="ft-title-hotline">Tổng đài:</p>
          <p className="ft-phone">0907.533.288</p>
        </div>
        <div className="ft-hotline">
          <p className="ft-title-hotline">Tổng đài:</p>
          <p className="ft-phone">0938.478.918</p>
        </div>
        <div className="ft-hotline">
          <p className="ft-title-hotline">Tổng đài:</p>
          <p className="ft-phone">0933.206.198</p>
        </div>
        <div className="ft-hotline">
          <p className="ft-title-hotline">Tổng đài:</p>
          <p className="ft-phone">0903.603.198</p>
        </div>
        <div className="ft-title-row-1">
          <p className="ft-head">Kết nối với chúng tôi:</p>
          <div className="ft-social">
            <Link to="https://www.facebook.com/profile.php?id=100075532252255" target="_blank">
              <img
                src={process.env.PUBLIC_URL + "/img/icon_social/facebook.png"}
                alt=""
              />
            </Link>
            <Link target="_blank" to="https://zalo.me/84907533288">
              <img
                src={process.env.PUBLIC_URL + "/img/icon_social/zalo.png"}
                alt=""
              />
            </Link>
            <Link target="_blank" to="https://www.youtube.com/@dcykluugia">
              <img
                src={process.env.PUBLIC_URL + "/img/icon_social/youtube.png"}
                alt=""
              />
            </Link>
          </div>
        </div>
        <div className="ft-title-row">
          <Link target="_blank" to="http://online.gov.vn/Home/WebDetails/113207">
            <img src="./img/da-thong-bao-bo-cong-thuong.png" alt="" />
          </Link>
          {/* <Link to="/">
            <img src="./img/da-dang-ky-bo-cong-thuong.png" alt="" />
          </Link> */}
        </div>

        <div className="ft-title-row">
          <p className="ft-head">Lượt truy cập:</p>
        </div>
        <div style={{ padding: "0 5px 20px 5px" }} className="visit">
          <p style={{ fontSize: "14px" }}>Hôm nay: {todayVisits}</p>
          <p style={{ fontSize: "14px" }}>Tổng: {totalVisits}</p>
        </div>
        <iframe
          title="Y khoa lưu gia"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244.9631609884674!2d106.66783294070213!3d10.779832768754062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752eda79056eab%3A0xf051ed1857c6e1bd!2zcDE1LCAxOTggVMO0IEhp4bq_biBUaMOgbmgsIFBoxrDhu51uZyAxNSwgUXXhuq1uIDEwLCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmgsIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1663819427374!5m2!1svi!2s"
          style={{ border: "none", alignSelf: "flex-start" }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </footer>
    </>
  );
}

export default Footer;
