import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useNavigate } from "react-router-dom";
function ForgetPassword() {
  const nav = useNavigate();
  const [email, setEmail] = useState("");
  const [validateEmail, setValidateEmail] = useState("");
  const validateEmailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showInputEmail, setShowInputEmail] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [validateNewPassword, setValidateNewPassword] = useState("");
  const [validateConfirmNewPassword, setValidateConfirmNewPassword] =
    useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [rePasswordType, setRePasswordType] = useState("password");
  const changeStateRePasswordType = () => {
    rePasswordType === "password"
      ? setRePasswordType("text")
      : setRePasswordType("password");
  };
  const [PasswordType, setPasswordType] = useState("password");
  const changeStatePasswordType = () => {
    PasswordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };
  // Hàm để tắt thông báo
  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };
  const showSuccessMessageDialog = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
      nav("/dang-nhap");
    }, 2000);
  };
  const handleCheckEmail = () => {
    if (!email) {
      setValidateEmail("Bạn chưa nhập email.");
      setTimeout(() => {
        setValidateEmail("");
      }, 2500);
      return;
    }
    if (!validateEmailRegex.test(email)) {
      setValidateEmail("Email không hợp lệ.");
      setTimeout(() => {
        setValidateEmail("");
      }, 2500);
      return;
    }
    setLoading(true);
    axios
      .post("/api/users/checkemail", { email })
      .then((response) => {
        sendEmailOTP();
      })
      .catch((error) => {
        setLoading(false);
        setValidateEmail("Địa chỉ email không tồn tại.");
        setTimeout(() => {
          setValidateEmail("");
        }, 2500);
      });
  };
  const changepassword = () => {
    if (newPassword.length < 8) {
      setValidateNewPassword("Mật khẩu phải lớn hơn 8 ký tự.");
      setTimeout(() => {
        setValidateNewPassword("");
      }, 2500);
      return;
    }
    if (newPassword != confirmNewPassword) {
      setValidateConfirmNewPassword("Mật khẩu không trùng khớp.");
      setTimeout(() => {
        setValidateConfirmNewPassword("");
      }, 2500);
      return;
    }
    setLoading(true);
    axios
      .post("/api/users/forgetpassword", {
        newPassword,
        email,
      })
      .then((response) => {
        showSuccessMessageDialog();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setValidateConfirmNewPassword(
          "Lỗi trong quá trình xử lý vui lòng thử lại sau."
        );
        setTimeout(() => {
          setValidateConfirmNewPassword("");
        }, 2500);
      });
  };
  const sendEmailOTP = () => {
    axios
      .post("/api/users/sendmail", { email })
      .then((response) => {
        setShowInputEmail(true);
        setLoading(false);
        startCountdown();
        setOtp(response.data.otp);
        console.log(response.data.otp);
      })
      .catch((error) => {
        setLoading(false);
        setValidateEmail("Máy chủ đang bảo trì vui lòng thử lại sau.");
        setTimeout(() => {
          setValidateEmail("");
        }, 2500);
      });
  };
  const [inputs, setInputs] = useState(["", "", "", ""]);
  const [otp, setOtp] = useState("");
  const [countOtp, setCountOtp] = useState(5);
  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const updatedInputs = [...inputs];
    updatedInputs[index] = value;

    setInputs(updatedInputs);

    if (value === "" || isNaN(value)) {
      return;
    }

    if (index < 3 && value !== "") {
      document.getElementById(`input${index + 1}`).focus();
    } else if (index === 3) {
      if (timeLeft == 0) {
        setValidateEmail("Mã OTP đã hết hạn");
        setTimeout(() => {
          setValidateEmail("");
        }, 2500);
        return;
      }
      if (countOtp == 0) {
        setValidateEmail("Đã quá số lần nhập OTP qui định.");
        setTimeout(() => {
          setValidateEmail("");
        }, 2500);
        return;
      }
      const userOtp = updatedInputs.join("");
      if (userOtp === otp) {
        setShowChangePassword(true);
      } else {
        setCountOtp(countOtp - 1);
        if (countOtp - 1 == 0) {
          setValidateEmail(`Quá số lần nhập OTP.`);
        } else {
          setValidateEmail(
            `OTP không trùng khớp, bạn còn ${countOtp - 1} lần nhập.`
          );
        }

        setTimeout(() => {
          setValidateEmail("");
        }, 2500);
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      if (index > 0) {
        const updatedInputs = [...inputs];
        updatedInputs[index] = "";
        setInputs(updatedInputs);
        document.getElementById(`input${index - 1}`).focus();
      }
    }
  };
  const handleInputChangePhone = (e, index) => {
    const { value } = e.target;
    const updatedInputs = [...inputs];
    updatedInputs[index] = value;

    setInputs(updatedInputs);

    if (value === "" || isNaN(value)) {
      return;
    }

    if (index < 3 && value !== "") {
      document.getElementById(`input-phone${index + 1}`).focus();
    } else if (index === 3) {
      if (timeLeft == 0) {
        setValidateEmail("Mã OTP đã hết hạn");
        setTimeout(() => {
          setValidateEmail("");
        }, 2500);
        return;
      }
      if (countOtp == 0) {
        setValidateEmail("Đã quá số lần nhập OTP qui định.");
        setTimeout(() => {
          setValidateEmail("");
        }, 2500);
        return;
      }
      const userOtp = updatedInputs.join("");
      if (userOtp === otp) {
        setShowChangePassword(true);
      } else {
        setCountOtp(countOtp - 1);
        setValidateEmail(`OTP không trùng khớp, bạn còn ${countOtp} lần nhập.`);
        setTimeout(() => {
          setValidateEmail("");
        }, 2500);
      }
    }
  };

  const handleKeyDownPhone = (e, index) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      if (index > 0) {
        const updatedInputs = [...inputs];
        updatedInputs[index] = "";
        setInputs(updatedInputs);
        document.getElementById(`input-phone${index - 1}`).focus();
      }
    }
  };
  const [timeLeft, setTimeLeft] = useState(0);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let timer;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isCounting, timeLeft]);

  const startCountdown = () => {
    setIsCounting(true);
    setTimeLeft(150);
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedTime = `${minutes}:${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;
    return formattedTime;
  };
  return (
    <>
      <div
        className="ft-p-lds-ring"
        style={{ display: loading ? "flex" : "none" }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {showSuccessMessage ? (
        <div className="ft-p-message-dialog">
          <div className="ft-p-title">
            <h2>Thông báo</h2>
            <button aria-label="close" className="ft-p-delete modal__button">
              <span
                onClick={closeSuccessMessage}
                className="ft-p-close-icon"
              ></span>
            </button>
          </div>
          <p>Thay Đổi Mật Khẩu Thành Công!</p>
          <img src="/img/check.png" />
        </div>
      ) : null}
      <Helmet>
        <title>Quên Mật Khẩu</title>
      </Helmet>
      <div className="ft-p-frame">
        <p className="ft-p-title">Đặt lại mật khẩu của bạn</p>

        <div className="ft-p-frame-body">
          <div className="ft-p-frm">
            {showChangePassword ? (
              <>
                <div className="ft-p-frame-password">
                  <div className="ft-p-frame-input">
                    <p>Mật khẩu mới</p>
                    <input
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                      type={PasswordType}
                      name="new-password"
                      id="new-password"
                      placeholder="Mật khẩu mới"
                    />
                  </div>
                  {validateNewPassword ? (
                    <div className="ft-p-error">{validateNewPassword}</div>
                  ) : null}
                </div>
                <div className="ft-p-frame-password">
                  <div className="ft-p-frame-input">
                    <p>Nhập lại mật khẩu</p>
                    <input
                      value={confirmNewPassword}
                      onChange={(e) => {
                        setConfirmNewPassword(e.target.value);
                      }}
                      type={rePasswordType}
                      name="confirm-password"
                      id="confirm-password"
                      placeholder="Nhập lại mật khẩu mới"
                    />
                  </div>
                  {validateConfirmNewPassword ? (
                    <div className="ft-p-error">
                      {validateConfirmNewPassword}
                    </div>
                  ) : null}
                </div>
                <button onClick={changepassword} id="next">
                  Đổi mật khẩu
                </button>
              </>
            ) : (
              <>
                {showInputEmail ? (
                  <p className="ft-p-title-1">
                    Một mã OTP gồm 4 chữ số đã được gửi đến email: {email}
                  </p>
                ) : (
                  <p className="ft-p-title-1">Email</p>
                )}
                {showInputEmail ? (
                  <>
                    <div id="inputs">
                      {inputs.map((value, index) => (
                        <input
                          disabled={timeLeft == 0 || countOtp == 0}
                          className="ft-p-otp"
                          id={`input${index}`}
                          key={index}
                          type="text"
                          maxLength="1"
                          value={value}
                          onChange={(e) => handleInputChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                      ))}
                    </div>
                    <div
                      style={{
                        "margin-top": "10px",
                        "align-self": "flex-start",
                        color: "#626262",
                      }}
                      className="ft-p-time"
                    >
                      {formatTime(timeLeft)}
                    </div>
                  </>
                ) : (
                  <div className="ft-p-email">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Địa chỉ email của bạn"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                )}
                {validateEmail && (
                  <div className="ft-p-error">{validateEmail}</div>
                )}
                {showInputEmail ? null : (
                  <button onClick={handleCheckEmail} id="next">
                    Tiếp Theo
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="ft-p-frame-phone">
        <p className="ft-p-title">Đặt lại mật khẩu của bạn</p>

        <div className="ft-p-frame-body-phone">
          <div className="ft-p-frm">
            {showChangePassword ? (
              <>
                <div className="ft-p-frame-password">
                  <div className="ft-p-frame-input">
                    <p>Mật khẩu mới</p>
                    <input
                      style={{ width: "95% !important" }}
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                      type={PasswordType}
                      name="new-password"
                      id="new-password"
                      placeholder="Mật khẩu mới"
                    />
                  </div>
                  {validateNewPassword ? (
                    <div className="ft-p-error">{validateNewPassword}</div>
                  ) : null}
                </div>
                <div className="ft-p-frame-password">
                  <div className="ft-p-frame-input">
                    <p>Nhập lại mật khẩu</p>
                    <input
                      style={{ width: "95% !important" }}
                      value={confirmNewPassword}
                      onChange={(e) => {
                        setConfirmNewPassword(e.target.value);
                      }}
                      type={rePasswordType}
                      name="confirm-password"
                      id="confirm-password"
                      placeholder="Nhập lại mật khẩu mới"
                    />
                  </div>
                  {validateConfirmNewPassword ? (
                    <div className="ft-p-error">
                      {validateConfirmNewPassword}
                    </div>
                  ) : null}
                </div>
                <button onClick={changepassword} id="next">
                  Đổi mật khẩu
                </button>
              </>
            ) : (
              <>
                {showInputEmail ? (
                  <p className="ft-p-title-1">
                    Một mã OTP gồm 4 chữ số đã được gửi đến email: {email}
                  </p>
                ) : (
                  <p className="ft-p-title-1">Email</p>
                )}
                {showInputEmail ? (
                  <>
                    <div id="inputs">
                      {inputs.map((value, index) => (
                        <input
                          type="number"
                          disabled={timeLeft == 0 || countOtp == 0}
                          className="ft-p-otp"
                          id={`input-phone${index}`}
                          key={index}
                          maxLength="1"
                          value={value}
                          onChange={(e) => handleInputChangePhone(e, index)}
                          onKeyDown={(e) => handleKeyDownPhone(e, index)}
                        />
                      ))}
                    </div>
                    <div
                      style={{
                        "margin-top": "10px",
                        "align-self": "flex-start",
                        color: "#626262",
                      }}
                      className="ft-p-time"
                    >
                      {formatTime(timeLeft)}
                    </div>
                  </>
                ) : (
                  <div className="ft-p-email">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Địa chỉ email của bạn"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                )}
                {validateEmail && (
                  <div className="ft-p-error">{validateEmail}</div>
                )}
                {showInputEmail ? null : (
                  <button onClick={handleCheckEmail} id="next">
                    Tiếp Theo
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
