import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
function PersonalPage() {
  const nav = useNavigate();
  const [statusScreenPhone, setStatusScreenPhone] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [name, setName] = useState("đang tải...");
  const [email, setEmail] = useState("đang tải...");
  const [phone, setPhone] = useState("đang tải...");
  const [address, setAddress] = useState("đang tải...");
  const [avatar, setAvatar] = useState("/img/avatar.jpg");
  const token = Cookies.get("accessToken");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [validateCurrentPassword, setValidateCurrentPassword] = useState("");
  const [validateNewPassword, setValidateNewPassword] = useState("");
  const [validateConfirmNewPassword, setValidateConfirmNewPassword] =
    useState("");
  const [validatePhone, setValidatePhone] = useState("");
  const [validateName, setValidateName] = useState("");
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const updateInfo = () => {
    if (name.length < 1) {
      setValidateName("Tên không được để trống.");
      setTimeout(() => setValidateName(""), 2500);
      return;
    }
    if (name.length > 25) {
      setValidateName("Tên không được quá 25 ký tự.");
      setTimeout(() => setValidateName(""), 2500);
      return;
    }
    if (phone.length < 1) {
      setValidatePhone("Số điện thoại không được để trống.");
      setTimeout(() => setValidatePhone(""), 2500);
      return;
    }

    const vietnamesePhoneNumberRegex = /^(0[1-9][0-9]{8,9})$/;
    if (!vietnamesePhoneNumberRegex.test(phone)) {
      setValidatePhone("Số điện thoại không hợp lệ.");
      setTimeout(() => setValidatePhone(""), 2500);
      return;
    }
    const data = {
      phone,
      name,
      image,
    };
    setLoading(true);
    axios
      .put("/api/users/updateinfor", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setMessage("Thay đổi thông tin cá nhân thành công!");
        showSuccessMessageDialog();
        setLoading(false);
      })
      .catch((error) => {
        setValidatePhone(
          "tạm thời không thể thay đổi thông tin, vui lòng thử lại sau!"
        );
        setTimeout(() => {
          setValidatePhone("");
        }, 2500);
        setLoading(false);
      });
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    // Kiểm tra nếu tệp không được chọn
    if (!selectedImage) {
      return;
    }

    // Kiểm tra định dạng của tệp ảnh (chỉ cho phép jpg và png)
    if (!selectedImage.type.match(/image\/(jpeg|jpg|png)/)) {
      setError("Chỉ cho phép định dạng jpg và png.");
      return;
    }

    // Kiểm tra kích thước của tệp ảnh (không lớn hơn 2MB)
    if (selectedImage.size > 2 * 1024 * 1024) {
      setError("Kích thước tệp ảnh phải nhỏ hơn 2MB.");
      return;
    }

    // Đọc tệp ảnh và chuyển thành base64
    const reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(selectedImage);
    // Xóa thông báo lỗi (nếu có)
    setError(null);
  };
  const resetImage = () => {
    setImage(null);
  };
  const handleChooseImageClick = () => {
    // Kích hoạt input file khi người dùng nhấn nút "Chọn ảnh"
    fileInputRef.current.click();
  };
  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };
  const showSuccessMessageDialog = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
      window.location.reload();
    }, 2000);
  };
  const changepassword = () => {
    if (newPassword.length < 8) {
      setValidateNewPassword("Mật khẩu phải lớn hơn 8 ký tự.");
      setTimeout(() => {
        setValidateNewPassword("");
      }, 2500);
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setValidateConfirmNewPassword("Mật khẩu không trùng khớp.");
      setTimeout(() => {
        setValidateConfirmNewPassword("");
      }, 2500);
      return;
    }
    setLoading(true);
    axios
      .post(
        "/api/users/changepassword",
        {
          currentPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setMessage("Thay đổi mật khẩu thành công!");
        setCurrentPassword("");
        setConfirmNewPassword("");
        setNewPassword("");
        showSuccessMessageDialog();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setValidateCurrentPassword("Mật khẩu hiện tại không khớp.");
        setTimeout(() => {
          setValidateCurrentPassword("");
        }, 2500);
      });
  };
  useEffect(() => {
    if (!token) {
      nav("/dang-nhap");
      window.location.reload();
    } else {
      axios
        .get("/api/users/current", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setName(response.data.user.fullName);
          setEmail(response.data.user.email);
          setPhone(response.data.user.phoneNumber);
          setAddress(response.data.user.address);
          setAvatar("/" + response.data.user.avatar);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);
  const [password, setPasword] = useState("password");
  const changeStatePassword = () => {
    password === "password" ? setPasword("text") : setPasword("password");
  };
  const [rePasswordType, setRePasswordType] = useState("password");
  const changeStateRePasswordType = () => {
    rePasswordType === "password"
      ? setRePasswordType("text")
      : setRePasswordType("password");
  };
  const [PasswordType, setPasswordType] = useState("password");
  const changeStatePasswordType = () => {
    PasswordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };
  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedInput = inputValue.replace(/[^0-9]/g, "").slice(0, 10);
    setPhone(sanitizedInput);
  };
  return (
    <>
      <div
        className="psp-lds-ring"
        style={{ display: loading ? "flex" : "none" }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {showSuccessMessage ? (
        <div className="psp-message-dialog">
          <div className="psp-title">
            <h2>Thông báo</h2>
            <button aria-label="close" className="psp-delete modal__button">
              <span
                onClick={closeSuccessMessage}
                className="psp-close-icon"
              ></span>
            </button>
          </div>
          <p>{message}</p>
          <img alt="dấu trang" src="/img/check.png" />
        </div>
      ) : null}
      <div className="psp-container-personal-page">
        <Helmet>
          <title>Trang Cá Nhân</title>
        </Helmet>
        <div className="psp-menu-bar">
          <Link to="/trang-ca-nhan" className="psp-active">
            <div className="psp-item-menu black">
              <img src="./img/information.png" alt="" />
              <p>Tài khoản của bạn</p>
            </div>
          </Link>
          <Link to="/lich-su-mua-hang">
            <div className="psp-item-menu">
              <img src="./img/history.png" alt="" />
              <p>Lịch sử mua hàng</p>
            </div>
          </Link>
          <Link
            onClick={() => {
              Cookies.remove("accessToken");
              nav("/dang-nhap");
              window.location.reload();
            }}
          >
            <div className="psp-item-menu">
              <img src="./img/logout.png" alt="" />
              <p>Đăng xuất</p>
            </div>
          </Link>
        </div>
        <div className="psp-column">
          <div className="psp-frame_infor">
            <div className="psp-header">
              <p>Thông Tin Tài Khoản</p>
            </div>
            <div className="psp-frames">
              <div className="psp-frame">
                <div className="psp-frame-input">
                  <p>Họ và tên</p>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  {validateName ? (
                    <div className="psp-error-infor">{validateName}</div>
                  ) : null}
                </div>
                <div className="psp-frame-input">
                  <p>Địa chỉ email</p>
                  <input type="text" disabled value={email} />
                </div>
                <div className="psp-frame-input">
                  <p>Số điện thoại</p>
                  <input
                    type="text"
                    value={phone}
                    onChange={handlePhoneChange}
                  />
                  {validatePhone ? (
                    <div className="psp-error-infor">{validatePhone}</div>
                  ) : null}
                </div>
                <div className="psp-frame-input">
                  <div className="psp-center-end">
                    <p>Địa chỉ giao hàng</p>
                    <Link to="/thay-doi-dia-chi">thay đổi địa chỉ</Link>
                  </div>
                  <div className="psp-detail-address">{address}</div>
                </div>
                <div className="psp-frame-input">
                  <button onClick={updateInfo}>Thay Đổi</button>
                </div>
              </div>
              <div className="psp-frame-avatar">
                {image ? (
                  <div className="psp-edit-avatar">
                    <img src={image} alt="" />
                    <div className="psp-close" onClick={resetImage}></div>
                  </div>
                ) : (
                  <img src={avatar} alt="" />
                )}
                {error ? <p className="psp-error-img-avatar">{error}</p> : null}
                <button onClick={handleChooseImageClick}>Chọn ảnh</button>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
              </div>
            </div>
          </div>
          <div className="psp-frame_infor">
            <div className="psp-header">
              <p>Thay Đổi Mật Khẩu</p>
            </div>
            <div className="psp-frame-password">
              <div className="psp-frame-input">
                <p>Mật khẩu hiện tại</p>
                <input
                  value={currentPassword}
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                  }}
                  type={password}
                  name="current-password"
                  id="current-password"
                  placeholder="Nhập mật khẩu hiện tại của bạn"
                />
                {password === "password" ? (
                  <i
                    onClick={changeStatePassword}
                    className="fa-regular fa-eye-slash eye"
                    style={{ color: "#827a7a" }}
                  />
                ) : (
                  <i
                    onClick={changeStatePassword}
                    className="fa-regular fa-eye eye"
                    style={{ color: "#827a7a" }}
                  />
                )}
              </div>
              {validateCurrentPassword ? (
                <div className="psp-error">{validateCurrentPassword}</div>
              ) : null}
            </div>
            <div className="psp-frame-password">
              <div className="psp-frame-input">
                <p>Mật khẩu mới</p>
                <input
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  type={PasswordType}
                  name="new-password"
                  id="new-password"
                  placeholder="Mật khẩu mới"
                />
                {PasswordType === "password" ? (
                  <i
                    onClick={changeStatePasswordType}
                    className="fa-regular fa-eye-slash eye"
                    style={{ color: "#827a7a" }}
                  />
                ) : (
                  <i
                    onClick={changeStatePasswordType}
                    className="fa-regular fa-eye eye"
                    style={{ color: "#827a7a" }}
                  />
                )}
              </div>
              {validateNewPassword ? (
                <div className="psp-error">{validateNewPassword}</div>
              ) : null}
            </div>
            <div className="psp-frame-password">
              <div className="psp-frame-input">
                <p>Nhập lại mật khẩu</p>
                <input
                  value={confirmNewPassword}
                  onChange={(e) => {
                    setConfirmNewPassword(e.target.value);
                  }}
                  type={rePasswordType}
                  name="confirm-password"
                  id="confirm-password"
                  placeholder="Nhập lại mật khẩu mới"
                />
                {rePasswordType === "password" ? (
                  <i
                    onClick={changeStateRePasswordType}
                    className="fa-regular fa-eye-slash eye"
                    style={{ color: "#827a7a" }}
                  />
                ) : (
                  <i
                    onClick={changeStateRePasswordType}
                    className="fa-regular fa-eye eye"
                    style={{ color: "#827a7a" }}
                  />
                )}
              </div>
              {validateConfirmNewPassword ? (
                <div className="psp-error">{validateConfirmNewPassword}</div>
              ) : null}
            </div>
            <div className="psp-frame-password" onClick={changepassword}>
              <button>Thay Đổi Mật Khẩu</button>
            </div>
          </div>
        </div>
      </div>
      <div className="psp-container-personal-page-phone">
        <div className="psp-header-title-infor">
          <p
            onClick={() => {
              nav("/");
            }}
            className="psp-title"
          >
            Trang chủ
          </p>
          <p style={{ padding: "0 5px" }} className="psp-title">
            /
          </p>
          <p
            onClick={() => {
              setStatusScreenPhone(0);
            }}
            className="psp-title"
          >
            Tài khoản của bạn
          </p>
          {statusScreenPhone === 1 ? (
            <>
              <p style={{ padding: "0 5px" }} className="psp-title">
                /
              </p>
              <p style={{ color: "#1250DC" }} className="psp-title">
                Thông tin cá nhân
              </p>
            </>
          ) : null}
          {statusScreenPhone === 2 ? (
            <>
              <p style={{ padding: "0 5px" }} className="psp-title">
                /
              </p>
              <p style={{ color: "#1250DC" }} className="psp-title">
                Thay Đổi Mật Khẩu
              </p>
            </>
          ) : null}
        </div>
        {statusScreenPhone === 0 ? (
          <>
            <div className="psp-avt-name">
              <img src={avatar} alt="" />
              <p className="psp-name">{name}</p>
            </div>
            <div
              className="psp-frame-to-detail"
              onClick={() => {
                setStatusScreenPhone(1);
              }}
            >
              <p className="psp-title">Thông tin cá nhân</p>
              <i
                className="fa-solid fa-chevron-right fa-xs"
                style={{ color: "#404258" }}
              ></i>
            </div>
            <div
              onClick={() => {
                setStatusScreenPhone(2);
              }}
              className="psp-frame-to-detail"
            >
              <p className="psp-title">Thay đổi mật khẩu</p>
              <i
                className="fa-solid fa-chevron-right fa-xs"
                style={{ color: "#404258" }}
              ></i>
            </div>
            <div
              onClick={() => {
                nav("/thay-doi-dia-chi");
              }}
              className="psp-frame-to-detail"
            >
              <p className="psp-title">Thay đổi địa chỉ</p>
              <i
                className="fa-solid fa-chevron-right fa-xs"
                style={{ color: "#404258" }}
              ></i>
            </div>
            <div
              onClick={() => {
                nav("/lich-su-mua-hang");
              }}
              className="psp-frame-to-detail"
            >
              <p className="psp-title">Lịch sử mua hàng</p>
              <i
                className="fa-solid fa-chevron-right fa-xs"
                style={{ color: "#404258" }}
              ></i>
            </div>
            <div className="psp-frame-to-detail">
              <p className="psp-title">Đăng xuất</p>
              <i
                className="fa-solid fa-chevron-right fa-xs"
                style={{ color: "#00000000" }}
              ></i>
            </div>
          </>
        ) : statusScreenPhone === 1 ? (
          <>
            <div className="psp-avt-name">
              {image ? (
                <div className="psp-edit-avatar">
                  <img src={image} alt="" />
                  <div className="psp-close" onClick={resetImage}></div>
                </div>
              ) : (
                <img src={avatar} alt="" />
              )}
              {error ? <p className="psp-error-img-avatar">{error}</p> : null}
              <button onClick={handleChooseImageClick}>Chọn ảnh</button>
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={handleImageChange}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <p className="psp-name">{name}</p>
            </div>
            <div className="psp-frames">
              <div className="psp-frame">
                <div className="psp-frame-input">
                  <p>Họ và tên</p>
                  <input
                    type="text"
                    value={name}
                    style={{ fontSize: "12px" }}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  {validateName ? (
                    <div className="psp-error-infor">{validateName}</div>
                  ) : null}
                </div>
                <div className="psp-frame-input">
                  <p>Địa chỉ email</p>
                  <input
                    type="text"
                    disabled
                    style={{ fontSize: "12px" }}
                    value={email}
                  />
                </div>
                <div className="psp-frame-input">
                  <p>Số điện thoại</p>
                  <input
                    type="text"
                    value={phone}
                    style={{ fontSize: "12px" }}
                    onChange={handlePhoneChange}
                  />
                  {validatePhone ? (
                    <div className="psp-error-infor">{validatePhone}</div>
                  ) : null}
                </div>
                <div className="psp-frame-input">
                  <div className="psp-center-end">
                    <p>Địa chỉ giao hàng</p>
                    <Link to="/thay-doi-dia-chi">thay đổi địa chỉ</Link>
                  </div>
                  <div className="psp-detail-address">{address}</div>
                </div>
                <div className="psp-frame-input">
                  <button onClick={updateInfo}>Thay Đổi</button>
                </div>
              </div>
            </div>
          </>
        ) : statusScreenPhone === 2 ? (
          <>
            <div className="psp-frame_infor">
              <div className="psp-header">
                <p>Thay Đổi Mật Khẩu</p>
              </div>
              <div className="psp-frame-password">
                <div className="psp-frame-input">
                  <p>Mật khẩu hiện tại</p>
                  <input
                    value={currentPassword}
                    style={{ fontSize: "12px" }}
                    onChange={(e) => {
                      setCurrentPassword(e.target.value);
                    }}
                    type={password}
                    name="current-password"
                    id="current-password"
                    placeholder="Nhập mật khẩu hiện tại của bạn"
                  />
                  {password === "password" ? (
                    <i
                      onClick={changeStatePassword}
                      className="fa-regular fa-eye-slash eye"
                      style={{ color: "#827a7a" }}
                    />
                  ) : (
                    <i
                      onClick={changeStatePassword}
                      className="fa-regular fa-eye eye"
                      style={{ color: "#827a7a" }}
                    />
                  )}
                </div>
                {validateCurrentPassword ? (
                  <div className="psp-error-phone">
                    {validateCurrentPassword}
                  </div>
                ) : null}
              </div>
              <div className="psp-frame-password">
                <div className="psp-frame-input">
                  <p>Mật khẩu mới</p>
                  <input
                    value={newPassword}
                    style={{ fontSize: "12px" }}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    type={PasswordType}
                    name="new-password"
                    id="new-password"
                    placeholder="Mật khẩu mới"
                  />
                  {PasswordType === "password" ? (
                    <i
                      onClick={changeStatePasswordType}
                      className="fa-regular fa-eye-slash eye"
                      style={{ color: "#827a7a" }}
                    />
                  ) : (
                    <i
                      onClick={changeStatePasswordType}
                      className="fa-regular fa-eye eye"
                      style={{ color: "#827a7a" }}
                    />
                  )}
                </div>
                {validateNewPassword ? (
                  <div className="psp-error-phone">{validateNewPassword}</div>
                ) : null}
              </div>
              <div className="psp-frame-password">
                <div className="psp-frame-input">
                  <p>Nhập lại mật khẩu</p>
                  <input
                    value={confirmNewPassword}
                    style={{ fontSize: "12px" }}
                    onChange={(e) => {
                      setConfirmNewPassword(e.target.value);
                    }}
                    type={rePasswordType}
                    name="confirm-password"
                    id="confirm-password"
                    placeholder="Nhập lại mật khẩu mới"
                  />
                  {rePasswordType === "password" ? (
                    <i
                      onClick={changeStateRePasswordType}
                      className="fa-regular fa-eye-slash eye"
                      style={{ color: "#827a7a" }}
                    />
                  ) : (
                    <i
                      onClick={changeStateRePasswordType}
                      className="fa-regular fa-eye eye"
                      style={{ color: "#827a7a" }}
                    />
                  )}
                </div>
                {validateConfirmNewPassword ? (
                  <div className="psp-error-phone">
                    {validateConfirmNewPassword}
                  </div>
                ) : null}
              </div>
              <div className="psp-frame-password" onClick={changepassword}>
                <button>Thay Đổi Mật Khẩu</button>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default PersonalPage;
