import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
function SystemStore() {
  const [selected, setSelected] = useState(1);

  return (
    <>
      <Helmet>
        <title>Hệ thống cửa hàng </title>
      </Helmet>
      <div className="body-system-store">
        <div className="left-nav">
          <ul className="list-tab">
            <li
              onClick={() => {
                setSelected(1);
              }}
              className={`tab ${selected === 1 ? "active" : ""}`}
            >
              Cơ Sở 1
            </li>
            <li
              onClick={() => {
                setSelected(2);
              }}
              className={`tab ${selected === 2 ? "active" : ""}`}
            >
              Cơ Sở 2
            </li>
            <li
              onClick={() => {
                setSelected(3);
              }}
              className={`tab ${selected === 3 ? "active" : ""}`}
            >
              Cơ Sở 3
            </li>
          </ul>
        </div>
        <div className="right-nav">
          <div
            style={{ display: selected === 1 ? "flex" : "none" }}
            className="content-tab"
          >
            <p>
              <strong>
                <i class="fa fa-map-marker" aria-hidden="true"></i> Địa chỉ:
              </strong>
              Số 164 Thành Thái, P.12, Quận 10, TP.Hồ Chí Minh (Gần viện tim
              TP.Hồ Chí Minh)
            </p>
            <p>
              <strong>
                <i class="fa fa-phone" aria-hidden="true"></i> Hotline:
              </strong>
              <span style={{ color: " #ed1c24" }}>
                <strong>0933.875.008</strong>
              </span>
            </p>
            <p>
              <strong>
                <i class="fa fa-envelope-o" aria-hidden="true"></i> Email:
              </strong>
              luugiamedical@gmail.com
            </p>
            <p>
              <iframe
              title="store 1"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.465984827939!2d106.65918667778008!3d10.775577661972436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752ec4b1942885%3A0x7454eb8ceb241ba8!2zMTY0IMSQLiBUaMOgbmggVGjDoWksIFBoxrDhu51uZyAxMiwgUXXhuq1uIDEwLCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmgsIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1698822310872!5m2!1svi!2s"
                width="100%"
                height="450"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </p>
          </div>
          <div
            style={{ display: selected === 2 ? "flex" : "none" }}
            className="content-tab"
          >
            <p>
              <strong>
                <i class="fa fa-map-marker" aria-hidden="true"></i> Địa chỉ:
              </strong>
              198 Tô Hiến Thành, P.15, Quận 10, TP.Hồ Chí Minh (Đầu chợ sỉ thuốc
              Sài Gòn)
            </p>
            <p>
              <strong>
                <i class="fa fa-phone" aria-hidden="true"></i> Hotline:
              </strong>
              <span style={{ color: " #ed1c24" }}>
                <strong>0933.533.058</strong>
              </span>
            </p>
            <p>
              <strong>
                <i class="fa fa-envelope-o" aria-hidden="true"></i> Email:
              </strong>
              luugiamedical@gmail.com
            </p>
            <p>
              <iframe
              title="store 2"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2067.097025254452!2d106.66668911782068!3d10.779873634878776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752eda79056eab%3A0xeea9e06f25885174!2zMTk4IMSQLiBUw7QgSGnhur9uIFRow6BuaCwgUGjGsOG7nW5nIDE1LCBRdeG6rW4gMTAsIFRow6BuaCBwaOG7kSBI4buTIENow60gTWluaCwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1698822154627!5m2!1svi!2s"
                width="100%"
                height="450"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </p>
          </div>
          <div
            style={{ display: selected === 3 ? "flex" : "none" }}
            className="content-tab"
          >
            <p>
              <strong>
                <i class="fa fa-map-marker" aria-hidden="true"></i> Địa chỉ:
              </strong>
              Số 1 Đoàn Hoàn Minh, P.6, TP.Bến Tre (Cạnh bệnh viện Minh Đức)
            </p>
            <p>
              <strong>
                <i class="fa fa-phone" aria-hidden="true"></i> Hotline:
              </strong>
              <span style={{ color: " #ed1c24" }}>
                <strong>0933.363.531</strong>
              </span>
            </p>
            <p>
              <strong>
                <i class="fa fa-envelope-o" aria-hidden="true"></i> Email:
              </strong>
              luugiamedical@gmail.com
            </p>
            <p>
              <iframe
              title="store 3"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3926.2688965093757!2d106.36460057503434!3d10.239883589878087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDE0JzIzLjYiTiAxMDbCsDIyJzAxLjgiRQ!5e0!3m2!1svi!2s!4v1702306669222!5m2!1svi!2s"
                width="100%"
                height="450"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SystemStore;
