import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { motion } from "framer-motion";
import { format } from "date-fns";
import {
  ShimmerSectionHeader,
  ShimmerButton,
  ShimmerContentBlock,
  ShimmerPostItem,
} from "react-shimmer-effects";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
const unidecode = require("unidecode");
function transformToSlug(inputString) {
  const slug = unidecode(inputString)
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return slug;
}
function DetailProduct(props) {
  const updateCartItemCount = (newCount) => {
    props.updateCartItemCount(newCount);
  };
  const [quantity, setQuantity] = useState(1);
  const [shimmer, setShimmer] = useState(true);
  const handleIncreaseQuantity = () => {
    if (quantity < 10) {
      setQuantity(quantity + 1);
    }
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const URlSERVER = "/";

  const { productId } = useParams();
  const [reviews, setReviews] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [indexHeader, setIndexHeader] = useState(0);
  const [rating, setRating] = useState(null);
  let valueHeader = [
    "Thông Tin Sản Phẩm",
    "Thông Số Kỹ Thuật",
    "Đánh Giá Sản Phẩm",
  ];
  let valueHeaderPhone = ["Mô tả", "Thông Số Kỹ Thuật", "Đánh Giá"];
  const [product, setProduct] = useState(null);
  const [specification, setSpecification] = useState(null);
  const [imageProduct, setImageProduct] = useState(null);
  const [brand, setBrand] = useState(null);
  const [category, setCategory] = useState(null);
  const [categoryParents, setCategoryParents] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [relateProduct, setRelateProduct] = useState([]);
  const handlePreviousClick = () => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentImage < imageProduct.length - 1) {
      setCurrentImage(currentImage + 1);
    }
  };
  const handleImageClick = (index) => {
    setIsHovered(false);
    setCurrentImage(index);
  };
  useEffect(() => {
    setShimmer(true);
    axios
      .get(`/api/products/getbyname/${productId}`)
      .then((response) => {
        setProduct(response.data.product);
        setSpecification(response.data.specification);
        setImageProduct(response.data.imageProduct);
        setBrand(response.data.brand);
        setCategory(response.data.category);
        setCategoryParents(response.data.parentCategories);
        setRating(response.data.rating);
        axios
          .post("/api/reviews/getreviewsprd", {
            productId: response.data.product._id,
          })
          .then((res) => {
            setReviews(res.data);
          })
          .catch((e) => {})
          .finally(() => {
            axios
              .post("/api/products/relatedProduct", {
                productId: response.data.product._id,
              })
              .then((res) => {
                setRelateProduct(res.data);
              })
              .catch((e) => {})
              .finally(() => {
                setLoading(false);
                setShimmer(false);
              });
          });
      })
      .catch((error) => {});
  }, [productId]);
  const [loading, setLoading] = useState(false);
  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };
  const navigate = useNavigate();
  const token = Cookies.get("accessToken");
  const handleAddToCart = () => {
    if (!token) {
      setLoading(true);
      let productIdsInCart = [];

      // Kiểm tra xem productIds đã tồn tại trong localStorage chưa
      const productIdsInLocalStorage = localStorage.getItem("productIds");
      if (productIdsInLocalStorage) {
        productIdsInCart = JSON.parse(productIdsInLocalStorage);
      }

      // Kiểm tra xem productId đã tồn tại trong productIdsInCart chưa
      const productIndex = productIdsInCart.findIndex(
        (item) => item.productId === product.sku
      );

      if (productIndex === -1) {
        // Nếu productId chưa tồn tại, thêm vào mảng
        productIdsInCart.push({
          productId: product.sku,
          quantity: quantity,
        });

        // Lưu lại mảng productIds vào localStorage
        localStorage.setItem("productIds", JSON.stringify(productIdsInCart));
        setShowSuccessMessage(true);
        updateCartItemCount(productIdsInCart.length);
        setTimeout(closeSuccessMessage, 3000);
        setLoading(false);
      } else {
        productIdsInCart[productIndex].quantity += quantity;
        localStorage.setItem("productIds", JSON.stringify(productIdsInCart));
        setShowSuccessMessage(true);
        updateCartItemCount(productIdsInCart.length);
        setTimeout(closeSuccessMessage, 3000);
        setLoading(false);
      }
      return;
    }
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = {
      productId: product.sku,
      quantity: quantity,
    };
    setLoading(true);
    axios
      .post("/api/cart/add-to-cart", data, { headers })
      .then((response) => {
        setShowSuccessMessage(true);
        updateCartItemCount(response.data.count);
        setTimeout(closeSuccessMessage, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <>
      <Helmet>
        <title>{product ? product.productName : "Đang tải"}</title>
        <meta
          name="description"
          content={product ? product.description : "Mô tả sản phẩm"}
        />
        <meta
          name="keywords"
          content={product ? product.productName : "Từ khóa sản phẩm"}
        />

        {/* Open Graph tags */}
        <meta
          property="og:title"
          content={product ? product.productName : "Đang tải"}
        />
        <meta
          property="og:description"
          content={product ? product.description : "Mô tả sản phẩm"}
        />
        <meta
          property="og:image"
          content={product ? product.urlImage : "URL hình ảnh sản phẩm"}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="product" />
        <meta
          property="product:price:amount"
          content={product ? product.sellingPrice : "0"}
        />
        <meta property="product:price:currency" content="VND" />

        {/* Twitter Card tags */}
        <meta
          name="twitter:title"
          content={product ? product.productName : "Đang tải"}
        />
        <meta
          name="twitter:description"
          content={product ? product.description : "Mô tả sản phẩm"}
        />
        <meta
          name="twitter:image"
          content={product ? product.urlImage : "URL hình ảnh sản phẩm"}
        />

        {/* Other meta tags */}
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div
        className="dt-prd-lds-ring"
        style={{ display: loading ? "flex" : "none" }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {showSuccessMessage ? (
        <div className="dt-prd-message-dialog">
          <div className="dt-prd-title">
            <h2>Thông báo</h2>
            <button aria-label="close" className="dt-prd-delete modal__button">
              <span
                onClick={closeSuccessMessage}
                className="dt-prd-close-icon"
              ></span>
            </button>
          </div>
          <p>Đã thêm sản phẩm vào giỏ hàng!</p>
          <img src="/img/check.png" />
        </div>
      ) : null}
      {shimmer ? (
        <>
          <div className="shimmer-pc">
            <div className="shimmer-categories">
              <ShimmerButton size="sm" />
              <ShimmerButton size="sm" />
              <ShimmerButton size="sm" />
            </div>
            <div className="shimmer-row-main-prd">
              <ShimmerContentBlock title text cta card />
            </div>
            <ShimmerSectionHeader />
          </div>
          <div className="shimmer-phone-prd">
            <div className="shimmer-categories">
              <ShimmerButton size="sm" />
              <ShimmerButton size="sm" />
              <ShimmerButton size="sm" />
            </div>
            <div className="shimmer-column-main-prd">
              <ShimmerPostItem card title text cta />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="dt-prd-detail-product">
            <div className="dt-prd-classify">
              <img
                src={process.env.PUBLIC_URL + "/img/home.png"}
                alt=""
                className="dt-prd-home"
              />
              <img
                src={process.env.PUBLIC_URL + "/img/chevron.png"}
                alt=""
                className="dt-prd-left-arrow"
              />
              {categoryParents
                ? categoryParents.map((parentCategory, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Link
                          preventScrollReset={true}
                          to={`/danh-muc/${transformToSlug(
                            parentCategory.name
                          )}`}
                          className="dt-prd-category"
                        >
                          {parentCategory.name}
                        </Link>
                        <img
                          src={process.env.PUBLIC_URL + "/img/chevron.png"}
                          alt=""
                          className="dt-prd-left-arrow"
                        />
                      </React.Fragment>
                    );
                  })
                : null}

              <Link
                preventScrollReset={true}
                className="dt-prd-category dt-prd-active"
              >
                {product ? product.productName : "Đang tải"}
              </Link>
            </div>
            <div className="dt-prd-main-product">
              <div className="dt-prd-column-gallery">
                <img
                  src={process.env.PUBLIC_URL + "/img/up.png"}
                  alt=""
                  className="dt-prd-top-arrow"
                  onClick={handlePreviousClick}
                />
                <div className="dt-prd-column-images">
                  {Array.isArray(imageProduct) && imageProduct.length !== 0 ? (
                    <>
                      {imageProduct.map((image, index) => (
                        <div
                          className="dt-prd-frame-image"
                          key={index}
                          onMouseEnter={() => handleImageClick(index)}
                        >
                          <img src={URlSERVER + image.urlImage} alt="" />
                        </div>
                      ))}
                      {product.urlvideo ? (
                        <div
                          className="dt-prd-frame-image"
                          onMouseEnter={() => setIsHovered(true)}
                        >
                          <img src="/img/film.png" alt="" />
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
                <img
                  src={process.env.PUBLIC_URL + "/img/down.png"}
                  alt=""
                  className="dt-prd-bottom-arrow"
                  onClick={handleNextClick}
                />
              </div>
              {product && imageProduct ? (
                <>
                  {isHovered ? (
                    product.urlvideo ? (
                      <>
                        <iframe
                          className="dt-prd-main-image"
                          src={product.urlvideo}
                        ></iframe>
                      </>
                    ) : null
                  ) : (
                    <>
                      <img
                        src={URlSERVER + imageProduct[currentImage].urlImage}
                        alt=""
                        className="dt-prd-main-image"
                      />
                    </>
                  )}
                </>
              ) : null}
              <div className="dt-prd-infor-product">
                <h1 className="dt-prd-name">
                  {product ? product.productName : "Đang tải"}
                </h1>
                <div className="dt-prd-row-rate">
                  <div className="dt-prd-star">
                    {Array.from({ length: 5 }, (_, index) =>
                      index < rating.averageRating ? (
                        <span
                          style={{ fontSize: "14px" }}
                          className="fa fa-star  dt-prd-checked"
                        />
                      ) : (
                        <span
                          style={{ fontSize: "14px" }}
                          className="fa fa-star"
                        />
                      )
                    )}
                  </div>
                  <p className="dt-prd-text-rate">
                    {rating ? rating.totalReviews : "0"} đánh giá
                  </p>
                  <span className="dt-prd-dot" />
                  <div className="dt-prd-row-text">
                    <p className="dt-prd-bold">SKU:</p>
                    <p className="dt-prd-sku">
                      {product ? product.sku : "Đang tải"}
                    </p>
                  </div>
                </div>

                {product ? (
                  !product.contactToPurchase ? (
                    <div className="dt-prd-row-price">
                      {product.sellingPrice !== product.originalPrice ? (
                        <>
                          <p className="dt-prd-stroke-price">
                            {product.originalPrice.toLocaleString("vi-VN", {
                              style: "currency",
                              currency: "VND",
                            })}
                          </p>
                          <p className="dt-prd-price">
                            {product.sellingPrice.toLocaleString("vi-VN", {
                              style: "currency",
                              currency: "VND",
                            })}
                          </p>
                          <div className="dt-prd-frame-discount">
                            {(() => {
                              const originalPrice = product.originalPrice;
                              const sellingPrice = product.sellingPrice;
                              const discountValue =
                                originalPrice - sellingPrice;
                              const discountPercent =
                                (discountValue / originalPrice) * 100;

                              return `Giảm ${discountValue.toLocaleString(
                                "vi-VN",
                                {
                                  style: "currency",
                                  currency: "VND",
                                }
                              )} (${discountPercent.toFixed(0)}%)`;
                            })()}
                          </div>
                        </>
                      ) : (
                        <>
                          <p className="dt-prd-price">
                            {product.sellingPrice.toLocaleString("vi-VN", {
                              style: "currency",
                              currency: "VND",
                            })}
                          </p>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="dt-prd-row-price">
                      <p
                        className="dt-prd-price"
                        style={{
                          color: "#E10000",
                          fontFamily: "'Be Vietnam Pro', sans-serif;",
                        }}
                      >
                        Liên Hệ
                      </p>
                    </div>
                  )
                ) : null}
                <div className="dt-prd-row-brand">
                  <p>Thương hiệu:</p>
                  {brand ? (
                    <img src={URlSERVER + brand.urlImage} alt="" />
                  ) : null}
                </div>
                <div className="dt-prd-column-policy">
                  <div className="dt-prd-row-policy">
                    <p className="dt-prd-title">Bảo hành :</p>
                    <p className="dt-prd-content">
                      {product ? product.warrantyPeriod : null}
                    </p>
                  </div>
                  <div className="dt-prd-row-policy">
                    <p className="dt-prd-title">Trạng Thái :</p>
                    <p className="dt-prd-content red-bold">
                      {product
                        ? product.status
                          ? "Còn Hàng"
                          : "Hết Hàng"
                        : null}
                    </p>
                  </div>
                </div>
                <div className="dt-prd-row-type">
                  <p className="dt-prd-title-type">Loại:</p>
                  <Link
                    preventScrollReset={true}
                    to={`/danh-muc/${transformToSlug(category.name)}`}
                  >
                    {category ? category.name : null}
                  </Link>
                </div>
                {product ? (
                  !product.contactToPurchase ? (
                    <div className="dt-prd-row-quatity-add-to-cart">
                      <div className="dt-prd-frame-quatity">
                        <div
                          className="dt-prd-sign"
                          onClick={handleDecreaseQuantity}
                        >
                          <div className="dt-prd-minus" />
                        </div>
                        <div className="dt-prd-quatity">{quantity}</div>
                        <div
                          className="dt-prd-sign"
                          onClick={handleIncreaseQuantity}
                        >
                          <div className="dt-prd-plus-1" />
                          <div className="dt-prd-plus-2" />
                        </div>
                      </div>
                      <button
                        className="dt-prd-add-to-cart"
                        onClick={handleAddToCart}
                      >
                        <p>Thêm giỏ hàng</p>
                        <i
                          className="fa-solid fa-cart-shopping small"
                          style={{ color: "#ffffff" }}
                        />
                      </button>
                    </div>
                  ) : null
                ) : null}
              </div>
            </div>
            <div className="dt-prd-center-infor">
              {valueHeader.map((value, index) => (
                <div
                  onClick={() => {
                    setIndexHeader(index);
                  }}
                  key={index}
                  className={`dt-prd-header ${
                    indexHeader === index ? "dt-prd-active" : ""
                  }`}
                  data-tab="productReviews"
                >
                  {value}
                </div>
              ))}
            </div>
            {indexHeader === 2 ? (
              <div className="dt-prd-product-reviews" id="productReviews">
                {reviews.length == 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "400px",
                    }}
                  >
                    <p> Chưa có đánh giá nào về sản phẩm này!</p>
                  </div>
                ) : (
                  <>
                    {reviews.map((review) => (
                      <div className="dt-prd-review">
                        <div className="dt-prd-client">
                          <div className="dt-prd-left">
                            <img
                              src={`/${review.user.avatar}`}
                              alt=""
                              className="dt-prd-frame-avatar"
                            />
                            <div className="dt-prd-name-rate">
                              <p>{review.user.fullName}</p>
                              <div className="dt-prd-star">
                                {Array.from({ length: 5 }, (_, index) =>
                                  index < review.review.rating ? (
                                    <span
                                      style={{ fontSize: "14px" }}
                                      className="fa fa-star  dt-prd-checked"
                                    />
                                  ) : (
                                    <span
                                      style={{ fontSize: "14px" }}
                                      className="fa fa-star"
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="dt-prd-right">
                            <div className="dt-prd-time">
                              {format(
                                new Date(review.review.createdAt),
                                "HH:mm dd/MM/yyyy"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="dt-prd-comment">
                          <p>{review.review.reviewText}</p>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            ) : indexHeader === 1 ? (
              <div className="dt-prd-specifications" id="technicalInfo">
                <div className="dt-prd-title-1">
                  <p>Thông Số Kỹ Thuật</p>
                </div>
                {specification
                  ? specification.map((spc, index) => {
                      return (
                        <div
                          className={
                            index % 2 === 0 ? "dt-prd-row" : "dt-prd-row1"
                          }
                        >
                          <div className="dt-prd-title">{spc.title}</div>
                          <div className="dt-prd-stroke" />
                          <div className="dt-prd-title">{spc.content}</div>
                        </div>
                      );
                    })
                  : null}
              </div>
            ) : (
              <div className="dt-prd-information-product" id="productInfo">
                {product ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  />
                ) : (
                  "Đang tải"
                )}
              </div>
            )}
            <div className="dt-prd-same-product">
              <div className="dt-prd-title">Sản Phẩm Tương Tự</div>
              <div className="dt-prd-row-product">
                {relateProduct.map((product, index) => (
                  <Link
                    preventScrollReset={true}
                    to={
                      "/chi-tiet-san-pham/" +
                      transformToSlug(product.productName)
                    }
                    id="redirect-product"
                    key={index}
                  >
                    <div className="dt-prd-product">
                      <img src={`/${product.urlImage}`} alt="" />
                      <h5 className="dt-prd-name-product">
                        {product.productName}
                      </h5>
                      <div className="dt-prd-price">
                        {product.sellingPrice !== product.originalPrice ? (
                          <>
                            <p className="dt-prd-origin-price">
                              {product.originalPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                            <p className="dt-prd-offcial-price">
                              {product.sellingPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="dt-prd-offcial-price">
                              {product.sellingPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                          </>
                        )}
                      </div>
                      <div className="dt-prd-star">
                        {Array.from({ length: 5 }, (_, starIndex) => (
                          <span
                            className={
                              starIndex < product.averageRating
                                ? "fa fa-star dt-prd-checked"
                                : "fa fa-star"
                            }
                            key={starIndex}
                          />
                        ))}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <div className="dt-prd-trailer">
              <h1>HỆ THỐNG PHÂN PHỐI DỤNG CỤ Y TẾ CHÍNH HÃNG</h1>
              <h1>LƯU GIA</h1>
            </div>
            <div className="dt-prd-header-brain">Thương hiệu nổi bật</div>
            <div className="dt-prd-brain-icon">
              <img
                src={process.env.PUBLIC_URL + "/img/brand_icon/omron.png "}
                alt=" "
                className="dt-prd-img-brand-icon"
              />
              <img
                src={process.env.PUBLIC_URL + "/img/brand_icon/beurer.png "}
                alt=" "
                className="dt-prd-img-brand-icon"
              />
              <img
                src={process.env.PUBLIC_URL + "/img/brand_icon/microlife.png "}
                alt=" "
                className="dt-prd-img-brand-icon"
              />
              <img
                src={process.env.PUBLIC_URL + "/img/brand_icon/omron.png "}
                alt=" "
                className="dt-prd-img-brand-icon"
              />
            </div>
          </div>
          <div className="dt-prd-detail-product-phones">
            <div className="dt-prd-classify">
              <img
                src={process.env.PUBLIC_URL + "/img/home.png"}
                alt=""
                className="dt-prd-home"
              />
              <img
                src={process.env.PUBLIC_URL + "/img/chevron.png"}
                alt=""
                className="dt-prd-left-arrow"
              />
              {categoryParents
                ? categoryParents.map((parentCategory, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Link
                          preventScrollReset={true}
                          to={`/danh-muc/${transformToSlug(
                            parentCategory.name
                          )}`}
                          className="dt-prd-category"
                        >
                          {parentCategory.name}
                        </Link>
                        <img
                          src={process.env.PUBLIC_URL + "/img/chevron.png"}
                          alt=""
                          className="dt-prd-left-arrow"
                        />
                      </React.Fragment>
                    );
                  })
                : null}

              <Link
                preventScrollReset={true}
                className="dt-prd-category dt-prd-active"
              >
                {product ? product.productName : "Đang tải"}
              </Link>
            </div>
            <Swiper
              spaceBetween={0}
              centeredSlides={true}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation]}
              className="dt-prd-row-image-video-product"
            >
              {Array.isArray(imageProduct) && imageProduct.length !== 0 ? (
                <>
                  {imageProduct.map((image, index) => (
                    <SwiperSlide className="dt-prd-frame-image" key={index}>
                      <img src={URlSERVER + image.urlImage} alt="" />
                    </SwiperSlide>
                  ))}

                  {product.urlvideo ? (
                    <SwiperSlide className="dt-prd-frame-image">
                      <iframe
                        allowFullScreen
                        className="dt-prd-main-image"
                        src={product.urlvideo}
                      ></iframe>
                    </SwiperSlide>
                  ) : null}
                </>
              ) : null}
            </Swiper>
            <div className="dt-prd-infor-product">
              <h1 className="dt-prd-name">
                {product ? product.productName : "Đang tải"}
              </h1>
              <div className="dt-prd-row-rate">
                <div className="dt-prd-star">
                  {Array.from({ length: 5 }, (_, index) =>
                    index < rating.averageRating ? (
                      <span
                        style={{ fontSize: "14px" }}
                        className="fa fa-star  dt-prd-checked"
                      />
                    ) : (
                      <span
                        style={{ fontSize: "14px" }}
                        className="fa fa-star"
                      />
                    )
                  )}
                </div>
                <p className="dt-prd-text-rate">
                  {rating ? rating.totalReviews : "0"} đánh giá
                </p>
                <div className="dt-prd-row-text">
                  <p className="dt-prd-bold">SKU:</p>
                  <p className="dt-prd-sku">
                    {" "}
                    {product ? product.sku : "Đang tải"}
                  </p>
                </div>
              </div>

              {product ? (
                !product.contactToPurchase ? (
                  <div className="dt-prd-row-price">
                    {product.sellingPrice !== product.originalPrice ? (
                      <>
                        <p className="dt-prd-stroke-price">
                          {product.originalPrice.toLocaleString("vi-VN", {
                            style: "currency",
                            currency: "VND",
                          })}
                        </p>
                        <p className="dt-prd-price">
                          {product.sellingPrice.toLocaleString("vi-VN", {
                            style: "currency",
                            currency: "VND",
                          })}
                        </p>
                        <div className="dt-prd-frame-discount">
                          {(() => {
                            const originalPrice = product.originalPrice;
                            const sellingPrice = product.sellingPrice;
                            const discountValue = originalPrice - sellingPrice;
                            const discountPercent =
                              (discountValue / originalPrice) * 100;

                            return `Giảm ${discountValue.toLocaleString(
                              "vi-VN",
                              {
                                style: "currency",
                                currency: "VND",
                              }
                            )} (${discountPercent.toFixed(0)}%)`;
                          })()}
                        </div>
                      </>
                    ) : (
                      <>
                        <p className="dt-prd-price">
                          {product.sellingPrice.toLocaleString("vi-VN", {
                            style: "currency",
                            currency: "VND",
                          })}
                        </p>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="dt-prd-row-price">
                    <p
                      className="dt-prd-price"
                      style={{
                        color: "#E10000",
                        fontFamily: "'Be Vietnam Pro', sans-serif;",
                      }}
                    >
                      Liên Hệ
                    </p>
                  </div>
                )
              ) : null}
              <div className="dt-prd-row-brand">
                <p>Thương hiệu:</p>
                {brand ? <img src={URlSERVER + brand.urlImage} alt="" /> : null}
              </div>
              <div className="dt-prd-column-policy">
                <div className="dt-prd-row-policy">
                  <p className="dt-prd-title">Bảo hành :</p>
                  <p className="dt-prd-content">
                    {product ? product.warrantyPeriod : null}
                  </p>
                </div>
                <div className="dt-prd-row-policy">
                  <p className="dt-prd-title">Trạng Thái :</p>
                  <p className="dt-prd-content red-bold">
                    {product
                      ? product.status
                        ? "Còn Hàng"
                        : "Hết Hàng"
                      : null}
                  </p>
                </div>
              </div>
              <div className="dt-prd-row-type">
                <p className="dt-prd-title-type">Loại:</p>
                <Link
                  preventScrollReset={true}
                  to={`/danh-muc/${transformToSlug(category.name)}`}
                >
                  {category ? category.name : null}
                </Link>
              </div>
              {product ? (
                !product.contactToPurchase ? (
                  <div className="dt-prd-row-quatity-add-to-cart">
                    <div className="dt-prd-frame-quatity">
                      <motion.div
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.8 }}
                        className="dt-prd-sign"
                        onClick={handleDecreaseQuantity}
                      >
                        <div className="dt-prd-minus" />
                      </motion.div>
                      <div className="dt-prd-quatity">{quantity}</div>
                      <motion.div
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.8 }}
                        className="dt-prd-sign"
                        onClick={handleIncreaseQuantity}
                      >
                        <div className="dt-prd-plus-1" />
                        <div className="dt-prd-plus-2" />
                      </motion.div>
                    </div>
                    <motion.button
                      whileHover={{ scale: 1.2 }}
                      whileTap={{ scale: 0.8 }}
                      className="dt-prd-add-to-cart"
                      onClick={handleAddToCart}
                    >
                      <p>Thêm giỏ hàng</p>
                      <i
                        className="fa-solid fa-cart-shopping small"
                        style={{ color: "#ffffff" }}
                      />
                    </motion.button>
                  </div>
                ) : null
              ) : null}
            </div>
            <div className="dt-prd-center-infor-phone">
              {valueHeaderPhone.map((value, index) => (
                <div
                  onClick={() => {
                    setIndexHeader(index);
                  }}
                  key={index}
                  className={`dt-prd-header ${
                    indexHeader === index ? "dt-prd-active" : ""
                  }`}
                  data-tab="productReviews"
                >
                  {value}
                </div>
              ))}
            </div>
            {indexHeader === 2 ? (
              <div className="dt-prd-product-reviews" id="productReviews">
                {reviews.length == 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "400px",
                    }}
                  >
                    <p> Chưa có đánh giá nào về sản phẩm này!</p>
                  </div>
                ) : (
                  <>
                    {reviews.map((review) => (
                      <div className="dt-prd-review">
                        <div className="dt-prd-client">
                          <div className="dt-prd-left">
                            <img
                              src={`/${review.user.avatar}`}
                              alt=""
                              className="dt-prd-frame-avatar"
                            />
                            <div className="dt-prd-name-rate">
                              <p>{review.user.fullName}</p>
                              <div className="dt-prd-star">
                                {Array.from({ length: 5 }, (_, index) =>
                                  index < review.review.rating ? (
                                    <span
                                      style={{ fontSize: "14px" }}
                                      className="fa fa-star  dt-prd-checked"
                                    />
                                  ) : (
                                    <span
                                      style={{ fontSize: "14px" }}
                                      className="fa fa-star"
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="dt-prd-right">
                            <div className="dt-prd-time">
                              {format(
                                new Date(review.review.createdAt),
                                "HH:mm dd/MM/yyyy"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="dt-prd-comment">
                          <p>{review.review.reviewText}</p>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            ) : indexHeader === 1 ? (
              <div className="dt-prd-specifications" id="technicalInfo">
                <div className="dt-prd-title-1">
                  <p>Thông Số Kỹ Thuật</p>
                </div>
                {specification
                  ? specification.map((spc, index) => {
                      return (
                        <div
                          className={
                            index % 2 === 0 ? "dt-prd-row" : "dt-prd-row1"
                          }
                        >
                          <div className="dt-prd-title">{spc.title}</div>
                          <div className="dt-prd-stroke" />
                          <div className="dt-prd-title">{spc.content}</div>
                        </div>
                      );
                    })
                  : null}
              </div>
            ) : product ? (
              <div
                className="dt-prd-information-product"
                id="productInfo"
                dangerouslySetInnerHTML={{ __html: product.description }}
              />
            ) : (
              "Đang tải"
            )}
            <div className="dt-prd-title-same-product">Sản Phẩm Tương Tự</div>
            <Swiper
              slidesPerView={2}
              spaceBetween={0}
              className="dt-prd-row-products-phone"
            >
              {relateProduct.map((product, index) => (
                <SwiperSlide>
                  <Link
                    preventScrollReset={true}
                    to={
                      "/chi-tiet-san-pham/" +
                      transformToSlug(product.productName)
                    }
                    id="redirect-product-phone"
                  >
                    <div className="home-product">
                      {product.sellingPrice !== product.originalPrice
                        ? (() => {
                            const originalPrice = product.originalPrice;
                            const sellingPrice = product.sellingPrice;
                            const discountValue = originalPrice - sellingPrice;
                            const discountPercent =
                              (discountValue / originalPrice) * 100;
                            return (
                              <div className="home-discount">
                                <div className="home-clos">
                                  {discountPercent.toFixed(0)}%
                                </div>
                                <div className="home-col-d">
                                  <div className="home-left" />
                                  <div className="home-right" />
                                </div>
                              </div>
                            );
                          })()
                        : null}

                      <img src={URlSERVER + product.urlImage} alt="" />
                      <h5 className="home-name-product">
                        {product.productName}
                      </h5>
                      <div className="home-price">
                        {product.sellingPrice !== product.originalPrice ? (
                          <>
                            <p className="home-origin-price">
                              {product.originalPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                            <p className="home-offcial-price">
                              {product.sellingPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="home-offcial-price">
                              {product.sellingPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                          </>
                        )}
                      </div>
                      <div className="home-star">
                        {Array.from({ length: 5 }, (_, index) =>
                          index < product.averageRating ? (
                            <span className="fa fa-star checked" />
                          ) : (
                            <span className="fa fa-star" />
                          )
                        )}
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      )}
    </>
  );
}

export default DetailProduct;
