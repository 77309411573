import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
function ChangeAddress() {
  const nav = useNavigate();
  const token = Cookies.get("accessToken");
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [detailAddress, setDetailAddress] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedWard, setSelectedWard] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (!token) {
      nav("/dang-nhap");
      return;
    }
    // Fetch initial data for cities
    callAPI("https://vapi.vnappmob.com/api/province", "cities");
  }, []);

  const callAPI = (api, type) => {
    axios
      .get(api)
      .then((response) => {
        if (type === "cities") {
          console.log(response?.data?.results)
          setCities(response?.data?.results);
        } else if (type === "districts") {
          setDistricts(response?.data?.results);
        } else if (type === "wards") {
          setWards(response?.data?.results);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    setSelectedCity(selectedCity);
    setSelectedDistrict("");
    setSelectedWard("");
    const cityCode =
      event.target.options[event.target.selectedIndex].getAttribute("data-id");
    if (cityCode) {
      callAPI(
        `https://vapi.vnappmob.com/api/province/district/${cityCode}`,
        "districts"
      );
    }
  };

  const handleDistrictChange = (event) => {
    const selectedDistrict = event.target.value;
    setSelectedDistrict(selectedDistrict);
    setSelectedWard("");
    const districtCode =
      event.target.options[event.target.selectedIndex].getAttribute("data-id");
    if (districtCode) {
      callAPI(
        `https://vapi.vnappmob.com/api/province/ward/${districtCode}`,
        "wards"
      );
    }
  };
  const showSuccessMessageDialog = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
      nav("/trang-ca-nhan");
    }, 2000);
  };
  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };
  const handleWardChange = (event) => {
    const selectedWard = event.target.value;
    setSelectedWard(selectedWard);
  };
  const [validateAddress, setValidateAddress] = useState("");
  const HandleSaveAddress = () => {
    if (!detailAddress || !selectedCity || !selectedDistrict || !selectedWard) {
      setValidateAddress("Vui lòng nhập đầy đủ thông tin địa chỉ.");
      setTimeout(() => {
        setValidateAddress("");
      }, 2500);
      return;
    }
    const address =
      detailAddress +
      ", " +
      selectedWard +
      ", " +
      selectedDistrict +
      ", " +
      selectedCity +
      ".";
    axios
      .put(
        "/api/users/changeaddress",
        { address },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setMessage("Thay đổi thông tin cá nhân thành công!");
        showSuccessMessageDialog();
        setLoading(false);
      })
      .catch((error) => {
        setValidateAddress(
          "tạm thời không thể thay đổi thông tin, vui lòng thử lại sau!"
        );
        setTimeout(() => {
          setValidateAddress("");
        }, 2500);
        setLoading(false);
      });
  };
  return (
    <>
      <div
        className="change-address-lds-ring"
        style={{ display: loading ? "flex" : "none" }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {showSuccessMessage ? (
        <div className="change-address-message-dialog">
          <div className="change-address-title">
            <h2>Thông báo</h2>
            <button
              aria-label="close"
              className="change-address-delete modal__button"
            >
              <span
                onClick={closeSuccessMessage}
                className="change-address-close-icon"
              ></span>
            </button>
          </div>
          <p>{message}</p>
          <img src="/img/check.png" />
        </div>
      ) : null}
      <div className="change-address-container-personal-page">
        <Helmet>
          <title>Thông Tin Giao Hàng</title>
        </Helmet>
        <div className="change-address-menu-bar">
          <a className="change-address-active">
            <div className="change-address-item-menu black">
              <img src="./img/information.png" alt="" />
              <p>Tài khoản của bạn</p>
            </div>
          </a>
          <Link to="/lich-su-mua-hang">
            <div className="change-address-item-menu">
              <img src="./img/history.png" alt="" />
              <p>Lịch sử mua hàng</p>
            </div>
          </Link>
          <a>
            <div className="change-address-item-menu">
              <img src="./img/logout.png" alt="" />
              <p>Đăng xuất</p>
            </div>
          </a>
        </div>
        <div className="change-address-column">
          <div className="change-address-frame_infor">
            <div className="change-address-header">
              <p>Địa Chỉ Nhận Hàng</p>
            </div>
            <div className="change-address-frame-address">
              <div className="change-address-frame-input">
                <p>Địa chỉ cụ thể</p>
                <input
                  type="text"
                  placeholder="Nhập địa chỉ cụ thể của bạn"
                  value={detailAddress}
                  onChange={(e) => {
                    setDetailAddress(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="change-address-center">
              <div className="change-address-component">
                <p>Tỉnh / Thành Phố</p>
                <select
                  name="city"
                  className="change-address-dropdown"
                  value={selectedCity}
                  onChange={handleCityChange}
                >
                  <option value="" disabled>
                    Chọn tỉnh thành
                  </option>
                  {cities.map((city) => (
                    <option
                      key={city.province_id}
                      value={city.province_name}
                      data-id={city.province_id}
                    >
                      {city.province_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="change-address-component">
                <p>Quận / Huyện</p>
                <select
                  name="district"
                  className="change-address-dropdown"
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                  disabled={!selectedCity} // Disable if no city is selected
                >
                  <option value="" disabled>
                    Chọn quận huyện
                  </option>
                  {districts.map((district) => (
                    <option
                      key={district.district_id}
                      value={district.district_name}
                      data-id={district.district_id}
                    >
                      {district.district_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="change-address-component">
                <p>Phường / Xã</p>
                <select
                  name="ward"
                  className="change-address-dropdown"
                  value={selectedWard}
                  onChange={handleWardChange}
                  disabled={!selectedDistrict} // Disable if no district is selected
                >
                  <option value="" disabled>
                    Chọn phường xã
                  </option>
                  {wards.map((ward) => (
                    <option
                      key={ward.ward_id}
                      value={ward.ward_name}
                      data-id={ward.ward_id}
                    >
                      {ward.ward_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {validateAddress ? (
              <div className="change-address-error">{validateAddress}</div>
            ) : null}
            <div className="change-address-frame-address">
              <button onClick={HandleSaveAddress}>Lưu Thay Đổi</button>
            </div>
          </div>
        </div>
      </div>
      <div className="change-address-container-personal-page-phone">
        <div className="change-address-header-title-infor">
          <p
            onClick={() => {
              nav("/");
            }}
            className="change-address-title"
          >
            Trang chủ
          </p>
          <p style={{ padding: "0 5px" }} className="change-address-title">
            /
          </p>
          <p
            onClick={() => {
              nav("/trang-ca-nhan");
            }}
            className="change-address-title"
          >
            Tài khoản của bạn
          </p>
          <p style={{ padding: "0 5px" }} className="change-address-title">
            /
          </p>
          <p style={{ color: "#1250DC" }} className="change-address-title">
            Thay Đổi Địa Chỉ
          </p>
        </div>
        <div className="change-address-frame_infor">
          <div className="change-address-header">
            <p>Địa Chỉ Nhận Hàng</p>
          </div>
          <div className="change-address-frame-address">
            <div className="change-address-frame-input">
              <p>Địa chỉ cụ thể</p>
              <input
                type="text"
                placeholder="Nhập địa chỉ cụ thể của bạn"
                value={detailAddress}
                style={{ fontSize: "12px" }}
                onChange={(e) => {
                  setDetailAddress(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="change-address-center">
            <div className="change-address-component">
              <p>Tỉnh / Thành Phố</p>
              <select
                name="city"
                className="change-address-dropdown"
                value={selectedCity}
                onChange={handleCityChange}
              >
                <option value="" disabled>
                  Chọn tỉnh thành
                </option>
                {cities.map((city) => (
                  <option key={city.code} value={city.name} data-id={city.code}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="change-address-component">
              <p>Quận / Huyện</p>
              <select
                name="district"
                className="change-address-dropdown"
                value={selectedDistrict}
                onChange={handleDistrictChange}
                disabled={!selectedCity} // Disable if no city is selected
              >
                <option value="" disabled>
                  Chọn quận huyện
                </option>
                {districts.map((district) => (
                  <option
                    key={district.code}
                    value={district.name}
                    data-id={district.code}
                  >
                    {district.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="change-address-component">
              <p>Phường / Xã</p>
              <select
                name="ward"
                className="change-address-dropdown"
                value={selectedWard}
                onChange={handleWardChange}
                disabled={!selectedDistrict}
              >
                <option value="" disabled>
                  Chọn phường xã
                </option>
                {wards.map((ward) => (
                  <option key={ward.code} value={ward.name} data-id={ward.code}>
                    {ward.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {validateAddress ? (
            <div className="change-address-error">{validateAddress}</div>
          ) : null}
          <div className="change-address-frame-address">
            <button onClick={HandleSaveAddress}>Lưu Thay Đổi</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeAddress;
