import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { format } from "date-fns";
import { Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Button as BaseButton } from "@mui/base/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import SuccessNotification from "../SuccessNotification/SuccessNotification";
const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    max-width: 320px;
    width:240px;
    height: 200px !important;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);
const Button = styled(BaseButton)(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: ${blue[500]};
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: 1px solid ${blue[500]};
  box-shadow: 0 2px 1px ${
    theme.palette.mode === "dark"
      ? "rgba(0, 0, 0, 0.5)"
      : "rgba(45, 45, 60, 0.2)"
  }, inset 0 1.5px 1px ${blue[400]}, inset 0 -2px 1px ${blue[600]};

  &:hover {
    background-color: ${blue[600]};
  }

  &:active {
    background-color: ${blue[700]};
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${
      theme.palette.mode === "dark" ? blue[300] : blue[200]
    };
    outline: none;
  }

  &:disabled {
    background-color: ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    color: ${theme.palette.mode === "dark" ? grey[200] : grey[700]};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    cursor: not-allowed;
    box-shadow: none;

    &:hover {
      background-color: ${
        theme.palette.mode === "dark" ? grey[700] : grey[200]
      };
    }
  }
`
);
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#f2d20f",
  },
  "& .MuiRating-iconHover": {
    color: "#f2d20f",
  },
});
function DetailOrder() {
  const [open, setOpen] = React.useState(false);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [productID, setProductID] = useState(null);
  const token = Cookies.get("accessToken");
  const handleClickOpen = (productId) => {
    setProductID(productId);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleRate = () => {
    console.log(rating, review);
    axios
      .post(
        "/api/reviews/createReview",
        { rating, review, productID },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {})
      .catch((e) => {});
    setShowSuccessNotification(true);
    setRating(5);
    setReview("");
    setOpen(false);
  };
  const nav = useNavigate();
  const { code } = useParams();
  const [rating, setRating] = useState(5);
  const [review, setReview] = useState("");
  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };
  const [orderData, setOrderData] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isShowinfor, setIsShowinfor] = useState(false);
  const [isShowCancel, setIsShowCancel] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [cancelFail, setCancelFail] = useState(false);
  const [order, setOrder] = useState(false);
  const [statusOrder, setStatusOrder] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState(false);
  const [products, setProduct] = useState(false);
  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };
  const showSuccessMessageDialog = () => {
    setShowSuccessMessage(true);
    setTimeout(() => setShowSuccessMessage(false), 2000);
  };
  useEffect(() => {
    // Sử dụng Axios để gửi yêu cầu GET đến API
    axios
      .get(`/api/invoices/get-detail-invoice/${code}`)
      .then((response) => {
        setOrderData(response.data);
        setOrder(response.data.order);
        setStatusOrder(response.data.statusOrder);
        setInvoiceDetail(response.data.invoiceDetail);
        setProduct(response.data.products);
      })
      .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu đơn hàng:", error);
      });
  }, [code]);
  useEffect(() => {
    if (token) {
    } else {
      nav("/");
    }
  }, []);
  if (!orderData) {
    return <p>Loading...</p>;
  }

  const cancelInvoice = () => {
    setCancelSuccess(false);
    setCancelFail(false);
    setShowSuccessMessage(true);
    setIsShowCancel(true);
  };
  const cancelInvoiceHandler = () => {
    setShowSuccessMessage(false);
    setIsShowCancel(false);
    setLoading(true);
    axios
      .put(`/api/invoices/cancel-invoice/${code}`)
      .then((response) => {
        setStatusOrder(response.data.statusOrder);
        setShowSuccessMessage(true);
        setLoading(false);
        setCancelSuccess(true);
      })
      .catch((e) => {
        setShowSuccessMessage(true);
        setLoading(false);
        setCancelFail(true);
      });
  };

  const handleSuccessNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSuccessNotification(false);
  };
  const handleSuccess = () => {
    setShowSuccessNotification(true);
  };

  return (
    <>
      <SuccessNotification
        open={showSuccessNotification}
        handleClose={handleSuccessNotificationClose}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Đánh Giá Sản Phẩm
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography align="center">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <StyledRating
                name="customized-color"
                defaultValue={5}
                value={rating} // Sử dụng giá trị số sao
                onChange={handleRatingChange} // Xử lý sự kiện thay đổi số sao
                size="large"
                getLabelText={(value) =>
                  `${value} Heart${value !== 1 ? "s" : ""}`
                }
                icon={<StarIcon fontSize="inherit" />}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
            </Box>
          </Typography>
          <Typography align="center">
            <Textarea
              value={review} // Sử dụng giá trị nội dung đánh giá
              onChange={handleReviewChange} // Xử lý sự kiện thay đổi nội dung đánh giá
              maxRows={4}
              placeholder="Xin mời chia sẻ một số cảm nhận về sản phẩm"
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRate}>Đánh Giá</Button>
        </DialogActions>
      </BootstrapDialog>
      <Helmet>
        <title>Chi Tiết Hóa Đơn</title>
      </Helmet>
      <div
        className="detail-order-lds-ring"
        style={{ display: loading ? "flex" : "none" }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {showSuccessMessage ? (
        <div className="detail-order-message-dialog">
          <div className="detail-order-title">
            <h2>Thông báo</h2>
            <button
              aria-label="close"
              className="detail-order-delete modal__button"
            >
              <span
                onClick={closeSuccessMessage}
                className="detail-order-close-icon"
              ></span>
            </button>
          </div>
          {isShowCancel ? (
            <>
              <p>Bạn có chắc chắn hủy đơn hàng này ?</p>
              <img style={{ margin: "0" }} src="/img/alert.png" />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: "5px",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    color: "white",
                    padding: "8px 40px",
                    margin: "0 5px",
                    backgroundColor: "#db0505",
                    border: "1px solid #db0505",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={cancelInvoiceHandler}
                >
                  Hủy
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "black",
                    padding: "8px 40px",
                    margin: "0 5px",
                    backgroundColor: "white",
                    border: "1px solid #e4e4e4",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={closeSuccessMessage}
                >
                  Thoát
                </div>
              </div>
            </>
          ) : null}
          {cancelSuccess ? (
            <>
              <p>Hủy Thành Công!</p>
              <img src="/img/check.png" />
            </>
          ) : null}
          {cancelFail ? (
            <>
              <p>Hủy Thất Bại, Đơn Hàng Đang Được Chuẩn Bị!</p>
              <img src="/img/alert.png" />
            </>
          ) : null}
        </div>
      ) : null}
      <div className="detail-order-container-personal-page">
        <div className="detail-order-menu-bar">
          <Link to="/trang-ca-nhan">
            <div className="detail-order-item-menu ">
              <img src="/img/information.png" alt="" />
              <p>Tài khoản của bạn</p>
            </div>
          </Link>
          <Link to="/lich-su-mua-hang" className="detail-order-active">
            <div className="detail-order-item-menu black">
              <img src="/img/history.png" alt="" />
              <p>Lịch sử mua hàng</p>
            </div>
          </Link>
          <a
            onClick={() => {
              Cookies.remove("accessToken");
              nav("/login");
              window.location.reload();
            }}
          >
            <div className="detail-order-item-menu">
              <img src="/img/logout.png" alt="" />
              <p>Đăng xuất</p>
            </div>
          </a>
        </div>
        <div className="detail-order-column">
          <div className="detail-order-frame_infor">
            <div className="detail-order-header">
              <div className="detail-order-left">
                <p className="detail-order-title">Chi Tiết Đơn Hàng</p>
                <div className="detail-order-point" />
                <p className="detail-order-detail">
                  {format(new Date(statusOrder.createdAt), "HH:mm dd/MM/yyyy")}
                </p>
                <div className="detail-order-point" />
                <p className="detail-order-detail">
                  {invoiceDetail.length} sản phẩm
                </p>
                {!statusOrder.isShipping &&
                !statusOrder.isSuccess &&
                !statusOrder.isCancel ? (
                  <div
                    onClick={cancelInvoice}
                    className="detail-order-iscancel"
                  >
                    hủy đơn hàng
                  </div>
                ) : null}
              </div>
              <Link to="/lich-su-mua-hang">Quay lại</Link>
            </div>
            <div className="detail-order-infor_order">
              <div className="detail-order-infor_customer">
                <div className="detail-order-header-text">
                  <p>THÔNG TIN NHẬN HÀNG</p>
                </div>
                <p className="detail-order-title">TÊN KHÁCH HÀNG:</p>
                <p className="detail-order-content">{order.fullName}</p>
                <p className="detail-order-title">ĐỊA CHỈ NHẬN HÀNG:</p>
                <p className="detail-order-content">{order.shippingAddress}</p>
                <p className="detail-order-title">SỐ ĐIỆN THOẠI:</p>
                <p className="detail-order-content"> {order.shippingPhone}</p>
              </div>
              <div className="detail-order-infor_detail_order">
                <div className="detail-order-header-row">
                  <div className="detail-order-header-column">
                    <p className="detail-order-text-grey">MÃ ĐƠN HÀNG:</p>
                    <p className="detail-order-text-black"> {order.code}</p>
                  </div>
                  <div className="detail-order-vertical" />
                  <div className="detail-order-header-column">
                    <p className="detail-order-text-grey">
                      PHƯƠNG THỨC THANH TOÁN:
                    </p>
                    <p className="detail-order-text-black">Tiền Mặt</p>
                  </div>
                </div>
                <div className="detail-order-header-row-2">
                  <p className="detail-order-text-1">Tổng tiền :</p>
                  <p className="detail-order-text-2">
                    {order.total.toLocaleString("vi-VN", {
                      style: "currency",
                      currency: "VND",
                    })}
                  </p>
                </div>
              </div>
            </div>
            <div className="detail-order-progess-status">
              {statusOrder.isCancel ? (
                <div className={`detail-order-cancel`}>
                  x<p className={`detail-order-active`}>Đơn hàng đã hủy</p>
                </div>
              ) : (
                <>
                  <div
                    className={` detail-order-circle ${" detail-order-active"}`}
                  >
                    1
                    <p
                      className={` detail-order-active ${" detail-order-active"}`}
                    >
                      Đơn hàng đã đặt
                    </p>
                  </div>
                  <div
                    className={` detail-order-bar ${
                      statusOrder.confirmInvoice ||
                      statusOrder.isShipping ||
                      statusOrder.isSuccess
                        ? " detail-order-active"
                        : ""
                    }`}
                  />
                  <div
                    className={` detail-order-circle ${
                      statusOrder.confirmInvoice ||
                      statusOrder.isShipping ||
                      statusOrder.isSuccess
                        ? " detail-order-active"
                        : ""
                    }`}
                  >
                    2
                    <p
                      className={` detail-order-active ${
                        statusOrder.confirmInvoice ||
                        statusOrder.isShipping ||
                        statusOrder.isSuccess
                          ? " detail-order-active"
                          : ""
                      }`}
                    >
                      Đã duyệt đơn hàng
                    </p>
                  </div>
                  <div
                    className={` detail-order-bar ${
                      statusOrder.isShipping || statusOrder.isSuccess
                        ? " detail-order-active"
                        : ""
                    }`}
                  />
                  <div
                    className={` detail-order-circle ${
                      statusOrder.isShipping || statusOrder.isSuccess
                        ? " detail-order-active"
                        : ""
                    }`}
                  >
                    3
                    <p
                      className={` detail-order-active ${
                        statusOrder.isShipping || statusOrder.isSuccess
                          ? " detail-order-active"
                          : ""
                      }`}
                    >
                      Đang vận chuyển
                    </p>
                  </div>
                  <div
                    className={` detail-order-bar ${
                      statusOrder.isSuccess ? " detail-order-active" : ""
                    }`}
                  />
                  <div
                    className={` detail-order-circle ${
                      statusOrder.isSuccess ? " detail-order-active" : ""
                    }`}
                  >
                    4
                    <p
                      className={` detail-order-active ${
                        statusOrder.isSuccess ? " detail-order-active" : ""
                      }`}
                    >
                      Đã nhận được hàng
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="detail-order-header-row-product">
              <div className="detail-order-name">TÊN SẢN PHẨM</div>
              <div className="detail-order-price">ĐƠN GIÁ</div>
              <div className="detail-order-quatity">SỐ LƯỢNG</div>
              <div className="detail-order-total-price">TỔNG TIỀN</div>
              {statusOrder.isSuccess ? (
                <div className="detail-order-total-price">CHỨC NĂNG</div>
              ) : null}
            </div>
            {products.map((product) => (
              <div
                className="detail-order-row-product"
                key={product.product._id}
              >
                <div className="detail-order-name">
                  <img src={"/" + product.product.urlImage} alt="" />
                  {product.product.productName}
                </div>
                <div className="detail-order-price">
                  {product.invoiceDetail[0].sellingPrice.toLocaleString(
                    "vi-VN",
                    {
                      style: "currency",
                      currency: "VND",
                    }
                  )}
                </div>
                <div className="detail-order-quatity">
                  x{product.invoiceDetail[0].quantity}
                </div>
                <div className="detail-order-total-price">
                  {(
                    product.invoiceDetail[0].sellingPrice *
                    product.invoiceDetail[0].quantity
                  ).toLocaleString("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  })}
                </div>
                {statusOrder.isSuccess ? (
                  <div className="detail-order-total-price">
                    <div
                      onClick={() => {
                        handleClickOpen(product.product._id);
                      }}
                      className="detail-order-success"
                    >
                      Đánh giá
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="detail-order-container-personal-page-phone">
        <div className="detail-order-header-title-infor">
          <p
            onClick={() => {
              nav("/");
            }}
            className="detail-order-title"
          >
            Trang chủ
          </p>
          <p style={{ padding: "0 5px" }} className="detail-order-title">
            /
          </p>
          <p
            onClick={() => {
              nav("/lich-su-mua-hang");
            }}
            className="detail-order-title"
          >
            Lịch sử mua hàng
          </p>
          <p style={{ padding: "0 5px" }} className="detail-order-title">
            /
          </p>
          <p style={{ color: "#1250DC" }} className="detail-order-title">
            Chi tiết đơn hàng
          </p>
        </div>
        <div
          style={{ marginBottom: "15px" }}
          className="detail-order-row-title-header"
        >
          <p>Chi tiết đơn hàng</p>
          <div className="detail-order-point" />
          <p style={{ fontSize: "14px", fontWeight: "400", color: "#4D4D4D" }}>
            {invoiceDetail.length} sản phẩm
          </p>
        </div>
        <div className="detail-order-infor-order-phone">
          <div className="detail-order-row-infor-phone">
            <p className="detail-order-title">Mã đơn hàng:</p>
            <p className="detail-order-content-detail">{order.code}</p>
          </div>
          <div className="detail-order-row-infor-phone">
            <p className="detail-order-title">Ngày đặt:</p>
            <p className="detail-order-content-detail">
              {format(new Date(statusOrder.createdAt), "HH:mm dd/MM/yyyy")}
            </p>
          </div>
          <div className="detail-order-row-infor-phone">
            <p className="detail-order-title">Người nhận:</p>
            <p className="detail-order-content-detail">{order.fullName}</p>
          </div>
          <div className="detail-order-row-infor-phone">
            <p className="detail-order-title">Số điện thoại:</p>
            <p className="detail-order-content-detail">{order.shippingPhone}</p>
          </div>
          <div className="detail-order-row-infor-phone">
            <p style={{ width: "20%" }} className="detail-order-title">
              Địa chỉ:
            </p>
            <p
              style={{ paddingLeft: "0", width: "80%" }}
              className="detail-order-content-detail"
            >
              {order.shippingAddress}
            </p>
          </div>
          <div className="detail-order-row-infor-phone">
            <p className="detail-order-title">Phương thức thanh toán:</p>
            <p className="detail-order-content-detail">Tiền mặt</p>
          </div>
          <div className="detail-order-row-infor-phone">
            <p className="detail-order-title">Tổng tiền:</p>
            <p
              style={{ color: "#2C742F" }}
              className="detail-order-content-detail"
            >
              {order.total.toLocaleString("vi-VN", {
                style: "currency",
                currency: "VND",
              })}
            </p>
          </div>
        </div>
        <div
          style={{ margin: "15px 0" }}
          className="detail-order-row-title-header"
        >
          <p>Trạng thái đơn hàng</p>
        </div>
        <div className="detail-order-progess-status">
          {statusOrder.isCancel ? (
            <div className={` detail-order-circle  detail-order-cancel`}>
              <p style={{ position: "relative", color: "#db0505" }}>x</p>
              <div style={{ color: "#db0505" }} className="detail-order-status">
                Đơn hàng đã hủy
              </div>
            </div>
          ) : (
            <>
              <div className={` detail-order-circle ${" detail-order-active"}`}>
                <p style={{ position: "relative", right: "75%" }}>1</p>
                <div className="detail-order-status">đã đặt</div>
              </div>
              <div
                className={` detail-order-bar ${
                  statusOrder.confirmInvoice ||
                  statusOrder.isShipping ||
                  statusOrder.isSuccess
                    ? " detail-order-active"
                    : ""
                }`}
              ></div>
              <div
                className={` detail-order-circle ${
                  statusOrder.confirmInvoice ||
                  statusOrder.isShipping ||
                  statusOrder.isSuccess
                    ? " detail-order-active"
                    : ""
                }`}
              >
                <p style={{ position: "relative", right: "57%" }}>2</p>
                <div className="detail-order-status">Đã duyệt</div>
              </div>
              <div
                className={` detail-order-bar ${
                  statusOrder.isShipping || statusOrder.isSuccess
                    ? " detail-order-active"
                    : ""
                }`}
              ></div>
              <div
                className={` detail-order-circle ${
                  statusOrder.isShipping || statusOrder.isSuccess
                    ? " detail-order-active"
                    : ""
                }`}
              >
                <p style={{ position: "relative" }}>3</p>
                <div className="detail-order-status">Đang vận chuyển</div>
              </div>
              <div
                className={` detail-order-bar ${
                  statusOrder.isSuccess ? " detail-order-active" : ""
                }`}
              ></div>
              <div
                className={` detail-order-circle ${
                  statusOrder.isSuccess ? " detail-order-active" : ""
                }`}
              >
                <p style={{ position: "relative", right: "65%" }}>4</p>
                <div className="detail-order-status">Đã nhận</div>
              </div>
            </>
          )}
        </div>
        <div
          style={{ margin: "5px 0" }}
          className="detail-order-row-title-header"
        >
          <p>Chi tiết sản phẩm</p>
        </div>
        {products.map((product) => (
          <div className="detail-order-row-product-phone">
            <div className="detail-order-image">
              <img src={"/" + product.product.urlImage} alt="" />
            </div>
            <div className="detail-order-infor-product">
              <p className="detail-order-name">{product.product.productName}</p>
              <div className="detail-order-detail-infor">
                <p className="detail-order-price">
                  {product.invoiceDetail[0].sellingPrice.toLocaleString(
                    "vi-VN",
                    {
                      style: "currency",
                      currency: "VND",
                    }
                  )}
                </p>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                  className="detail-order-quantity"
                >
                  SL: {product.invoiceDetail[0].quantity}
                  {statusOrder.isSuccess ? (
                    <div
                      onClick={() => {
                        handleClickOpen(product.product._id);
                      }}
                      className="detail-order-success"
                    >
                      Đánh giá
                    </div>
                  ) : null}
                </p>
              </div>
            </div>
          </div>
        ))}
        {!statusOrder.isShipping &&
        !statusOrder.isSuccess &&
        !statusOrder.isCancel ? (
          <div onClick={cancelInvoice} className="detail-order-cancel-order">
            Hủy đơn hàng
          </div>
        ) : null}
      </div>
    </>
  );
}

export default DetailOrder;
